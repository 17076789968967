import { Clear, Edit, Language, LocationOn, Phone } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import QRCode from "react-qr-code";
import "./InvoiceFooter.css";

const CustomInvoice = (props) => {
  const {
    orderInfo,
    shopInfo,
    orderId,
    newOrderToggle,
    setNewOrderToggle,
    newCustomerFieldInfo,
    addedItems,
    setAddedItems,
  } = props;
  console.log("newCustomerFieldInfo?", newCustomerFieldInfo);

  const initialValue = 0;
  let subTotal = addedItems?.reduce(
    (accumulator, currentValue) => accumulator + currentValue?.itemTotal,
    0
  );
  const deleteAddedItems = (sl) => {
    const filtered = addedItems?.filter((element, index) => index != sl);
    console.log("filtered:", filtered);
    setAddedItems(filtered);
  };

  return (
    <Paper
      // elevation={0}
      sx={{
        paddingTop: "40px",

        marginTop: "30px",
        marginBottom: "0px",
        position: "relative",
      }}
    >
      <Box sx={{ paddingX: "30px" }}>
        <Box sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <Stack
                alignItems="flex-start"
                justifyContent={"left"}
                sx={{ height: "90px" }}
              >
                <Stack
                  // spacing={0.5}
                  // direction={"row"}
                  justifyContent={"center"}
                  alignItems="flex-start"
                  sx={{ height: "100%" }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: 90,
                      paddingLeft: "45px",
                    }}
                    src={shopInfo?.shop_logo}
                  />
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "13pt",
                      fontWeight: 500,
                      paddingLeft: "45px",
                    }}
                  >
                    {shopInfo?.shop_name}
                  </Typography>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ height: "100%", marginLeft: "22px" }}
                >
                  <LocationOn sx={{ fontSize: "14pt" }} />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", maxWidth: "200px" }}
                  >
                    {shopInfo?.shop_address}
                  </Typography>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ height: "100%", marginLeft: "24px" }}
                >
                  <Phone sx={{ fontSize: "11pt" }} />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", maxWidth: "200px" }}
                  >
                    {shopInfo?.shop_phone}
                  </Typography>
                </Stack>
                {shopInfo?.shop_license && (
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      left: "35%",
                      top: "150px",
                      border: "2px solid #efc11b",
                      borderRadius: "6px",
                      paddingX: "10px",
                      paddingY: "2px",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontSize: "10pt", maxWidth: "200px" }}
                    >
                      {shopInfo?.shop_license}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
            <Grid item xs={4.5}>
              <Box>
                <Stack>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: "31pt",
                      fontWeight: 700,
                      marginTop: "-12px",
                      color: "#519b32",
                    }}
                  >
                    INVOICE
                  </Typography>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <Box sx={{ width: "35%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        INVOICE
                      </Typography>
                    </Box>
                    <Box sx={{ width: "65%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        : {orderId}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent={"left"}
                  >
                    <Box sx={{ width: "35%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        DATE
                      </Typography>
                    </Box>
                    <Box sx={{ width: "65%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        : {newCustomerFieldInfo?.date}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Stack sx={{ marginTop: "50px" }}>
          <Typography variant="p" sx={{ fontSize: "10pt", fontWeight: 600 }}>
            Customer Info:
          </Typography>
          <Box
            sx={{
              width: "150px",
              height: "4px",
              bgcolor: "#519b32",
              borderRadius: "10px",
            }}
          ></Box>
          <Stack direction={"row"} justifyContent={"left"} spacing={1}>
            <Typography variant="p" sx={{ fontSize: "11pt", fontWeight: 600 }}>
              {newCustomerFieldInfo?.name}
            </Typography>
            {
              <IconButton
                size="small"
                onClick={() => {
                  setNewOrderToggle(false);
                }}
              >
                <Edit sx={{ fontSize: "10pt", color: "black" }} />
              </IconButton>
            }
          </Stack>

          <Typography variant="p" sx={{ fontSize: "11pt" }}>
            {newCustomerFieldInfo?.email}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "10pt" }}>
            {newCustomerFieldInfo?.mobile}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-around"}
          sx={{
            paddingY: "2px",
            paddingX: "5px",
            marginTop: "25px",
            boxSizing: "border-box",
          }}
        >
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "8%",
              bgcolor: "#519b32",
              textAlign: "center",
            }}
          >
            SL
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "50%",
              bgcolor: "#519b32",
              textAlign: "center",
            }}
          >
            ITEM DESCRIPTION
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "8%",
              bgcolor: "#090940",
              textAlign: "center",
            }}
          >
            QTY
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "15%",
              bgcolor: "#090940",
              textAlign: "center",
            }}
          >
            RATE
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "15%",
              bgcolor: "#090940",
              textAlign: "center",
            }}
          >
            AMOUNT
          </Typography>
        </Stack>
        <Box sx={{ paddingTop: "15px" }}>
          {addedItems?.map((element, index) => (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-around"}
              sx={{
                paddingY: "2px",
                paddingX: "5px",
                marginTop: "1px",
              }}
            >
              <Stack
                direction={"row"}
                justifyContent={"left"}
                // alignItems="center"
                spacing={1}
                sx={{ width: "8%" }}
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    deleteAddedItems(index);
                  }}
                >
                  <Clear sx={{ fontSize: "10pt", color: "black" }} />
                </IconButton>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: 600,
                    bgcolor: "#d6d8d9",
                    textAlign: "center",
                    minHeight: "40px",
                  }}
                >
                  {index + 1}
                </Typography>
              </Stack>
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  width: "50%",
                  fontWeight: 500,
                  bgcolor: "#d6d8d9",
                  textAlign: "center",
                  minHeight: "40px",
                }}
              >
                {element?.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  width: "8%",
                  fontWeight: 600,
                  bgcolor: "#d6d8d9",
                  textAlign: "center",
                  minHeight: "40px",
                }}
              >
                {element?.quantity}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  width: "15%",
                  fontWeight: 600,
                  bgcolor: "#d6d8d9",
                  textAlign: "center",
                  minHeight: "40px",
                }}
              >
                {element.currency} {element?.price}
              </Typography>
              <Stack
                direction={"row"}
                justifyContent={"left"}
                spacing={3}
                sx={{ width: "15%" }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: 600,
                    bgcolor: "#d6d8d9",
                    textAlign: "center",
                    minHeight: "40px",
                  }}
                >
                  {element.currency} {element?.itemTotal}
                </Typography>
                {/* <IconButton
                size="small"
                onClick={() => {
                  setNewOrderToggle(false);
                }}
              >
                <Clear sx={{ fontSize: "10pt", color: "black" }} />
              </IconButton> */}
              </Stack>
            </Stack>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: "30px",
          }}
        >
          <Stack alignItems={"flex-end"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ paddingX: "29px", width: "230px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Subtotal
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600 }}>
                AED {subTotal}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ paddingX: "29px", width: "230px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Tax or vat(5%)
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600 }}>
                {/* BDT {orderInfo?.order_details[0]?.vat_itemTotal} */}
                AED {Math.round(subTotal * 0.05)}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ paddingX: "29px", width: "230px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Gateway Charge
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600 }}>
                AED 0
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                paddingX: "29px",
                width: "150px",
                bgcolor: "#519b32",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700, color: "white" }}
              >
                Total
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600, color: "white" }}>
                AED {Math.round(subTotal * 0.05) + subTotal}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                paddingX: "29px",
                width: "150px",
                bgcolor: "#519b32",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700, color: "white" }}
              >
                Paid
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600, color: "white" }}>
                AED {Math.round(subTotal * 0.05) + subTotal}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                paddingX: "29px",
                width: "150px",
                bgcolor: "#519b32",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700, color: "white" }}
              >
                Due
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600, color: "white" }}>
                AED 0
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack
          sx={{ width: "100%", marginTop: "70px" }}
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Stack
            sx={{ width: "40%", borderTop: "2px solid gray" }}
            direction="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography
              variant="p"
              sx={{ fontSize: "9pt", fontWeight: 500, textAlign: "center" }}
            >
              Receiver's signature
            </Typography>
          </Stack>
          <Stack
            sx={{ width: "40%", borderTop: "2px solid gray" }}
            direction="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography
              variant="p"
              sx={{ fontSize: "9pt", fontWeight: 500, textAlign: "center" }}
            >
              Signature
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ marginTop: "40px", width: "100%" }}
        >
          <Stack
            alignItems="flex-start"
            justifyContent={"left"}
            spacing={1}
            sx={{ width: "70%" }}
          >
            <Stack sx={{}}>
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 600 }}
              >
                Payment Method:
              </Typography>
              <Box
                sx={{
                  width: "118px",
                  height: "4px",
                  bgcolor: "#519b32",
                  borderRadius: "10px",
                }}
              ></Box>
              <Typography variant="p" sx={{ fontSize: "8pt" }}>
                CASH
              </Typography>
            </Stack>
            <Stack sx={{}}>
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 600 }}
              >
                Terms and Conditions:
              </Typography>
              <Box
                sx={{
                  width: "163px",
                  height: "4px",
                  bgcolor: "#519b32",
                  borderRadius: "10px",
                }}
              ></Box>
              <Typography variant="p" sx={{ fontSize: "8pt" }}>
                No,Exchange- No Return/ only service warranty
              </Typography>
            </Stack>
          </Stack>
          <Box
            style={{
              height: "auto",
              marginRight: "20px",
              maxWidth: 74,
              width: "100%",
            }}
          >
            {/* <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={
                "http://saraelectro.alqamaralzhabi.com/orderdetails/" + orderId
              }
              viewBox={`0 0 256 256`}
            /> */}
          </Box>
        </Stack>
      </Box>

      <Box id="footer">
        <Stack
          direction={"row"}
          spacing={0.1}
          justifyContent={"left"}
          alignItems="left"
          // marginTop={"10px"}
          sx={{ paddingX: "30px", marginTop: "20px" }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "8pt",
              fontStyle: "Italic",
              fontWeight: 200,
              marginBottom: "20px",
            }}
          >
            "This is a software generated invoice."
          </Typography>
        </Stack>
      </Box>
    </Paper>
  );
};

export default CustomInvoice;
