import { FavoriteBorder, HighlightOff } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useCart } from "react-use-cart";

const ShoppingCartItem = (props) => {
  const { id, quantity, title, price, count, currency, pic_url, category } =
    props.data;
  console.log("propssssss:", props);
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  // let amount = 0;
  let individualAmount = price * quantity;
  return (
    <Container>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", padding: "2%" }}
        spacing={{ xs: 2, sm: 2 }}
      >
        <Box
          component="img"
          sx={{
            padding: "0 12px",
            width: 110,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src={"http://saraelectro.alqamaralzhabi.com/admin_api/" + pic_url}
        />

        <Stack spacing={1}>
          <Typography variant="p" textAlign={{ xs: "center", sm: "left" }}>
            Name : {title}{" "}
          </Typography>

          <Typography variant="p" textAlign={{ xs: "center", sm: "left" }}>
            Price : {individualAmount} {currency}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={{ xs: 2, sm: 1 }}>
          <Button
            variant="contained"
            onClick={() => updateItemQuantity(id, quantity - 1)}
            className="ms-2"
          >
            -
          </Button>

          <span
            style={{
              color: "#000",
              padding: "7px 14px",
              border: "3px solid #777",
              borderRadius: "10px",
            }}
          >
            {quantity}
          </span>
          <Button
            variant="contained"
            onClick={() => updateItemQuantity(id, quantity + 1)}
            className="ms-2"
          >
            +
          </Button>
        </Stack>
        <Stack direction="row">
          <Button
            variant="contained"
            size="small"
            onClick={() => removeItem(id)}
            startIcon={<HighlightOff />}
            sx={{ bgcolor: "black" }}
          >
            Remove
          </Button>
          {/* <Button size="small">
            <FavoriteBorder />
          </Button> */}
        </Stack>
      </Stack>
      <Divider light />
    </Container>
  );
};

export default ShoppingCartItem;
