import {
  AssignmentInd,
  CheckCircleOutline,
  History,
  PlusOne,
} from "@mui/icons-material";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import banner2 from "../../images/banner2.jpg";

const CompanyProfile = () => {
  return (
    <Box sx={{ paddingBottom: "90px" }}>
      <Typography sx={{ margin: "auto", textAlign: "center" }}>
        ABOUT US
      </Typography>
      <Typography
        sx={{
          margin: "auto",
          fontSize: "25pt",
          fontWeight: 700,
          textAlign: "center",
          paddingBottom: "40px",
        }}
      >
        Company Profile
      </Typography>

      <Grid container spacing={2} sx={{ bgcolor: "" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ position: "relative", order: { xs: 0, md: "-1" } }}
        >
          <Stack spacing={4}>
            <Stack spacing={1.5}>
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, color: "#e76459" }}
              >
                What We Do
              </Typography>
              <Divider
                sx={{ width: "130px", borderTopColor: "red !important" }}
              >
                <AssignmentInd sx={{ color: "#3E4972" }} />
              </Divider>
              <Typography sx={{ fontSize: "12pt", color: "gray" }}>
                Welcome to Al QAMAR AL ZHABI, the ultimate destination for
                top-rated tech products and services. We are proud to offer a
                wide selection of the latest and most popular tech products and
                services, from computers and tablets to gaming consoles and TVs.
                At A Tech, we believe in providing our customers with the
                highest quality products and services at the most affordable
                prices. We are committed to always providing the most up-to-date
                technology, so our customers can stay connected and stay ahead
                of the game. Our knowledgeable and friendly staff is here to
                help you find the perfect solution for your tech needs. Whether
                you're looking for a new computer, tablet, gaming console, or
                television, we have the perfect model for you. We also provide
                repair and technical support services, so you can get the most
                out of your purchase. At Al QAMAR AL ZHABI, we are dedicated to
                providing our customers with the best experience possible. We
                strive to make sure each and every customer leaves with a smile
                on their face and a sense of satisfaction. Thank you for
                choosing Al QAMAR AL ZHABI for your tech needs!
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, color: "#e76459" }}
              >
                History And Ownership
              </Typography>
              <Divider
                sx={{ width: "130px", borderTopColor: "red !important" }}
              >
                <History sx={{ color: "#3E4972" }} />
              </Divider>
              <Typography sx={{ fontSize: "12pt", color: "gray" }}>
                Al QAMAR AL ZHABI General Trading LLC always tries to create
                great value and productivity gains for the clients by providing
                best quality solution to various business functions. We bring
                good experience in information technology, customer service,
                customer support and global operations by combining our
                strategic thinking with the best in Technology, People, and
                Process. We give our best while giving our services.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ order: { xs: -1, md: 0 } }}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Box
              component={"img"}
              src={banner2}
              sx={{ width: "90%", paddingTop: "90px" }}
            />
          </Stack>
        </Grid>
        {/* <Paper
          sx={{
            width: "60%",
            padding: "30px",
            margin: "auto",
            marginTop: "55px",
          }}
        >
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            spacing={3}
          >
            <Typography sx={{ fontSize: "20pt", fontWeight: 700 }}>
              Download Our Company Profile
            </Typography>
            <Button
              variant="contained"
              startIcon={<CheckCircleOutline />}
              sx={{
                fontSize: "12pt",
                fontWeight: 600,
                paddingX: "35px",
                paddingY: "8px",
                marginLeft: "8px",
                textTranstform: "capitalize",
                bgcolor: "#5461b9",
              }}
            >
              Download
            </Button>
          </Stack>
        </Paper> */}
        <Box
          sx={{
            marginTop: "40px",
            paddingLeft: "15px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              // width: { lg: "1150px", md: "50px", sm: "30px", xs: "30px" },
              textAlign: "center",
            }}
          >
            <iframe
              id="pdf"
              src="http://saraelectro.alqamaralzhabi.com/admin_api/files/al-qamar_profile.pdf"
              width="100%"
              height="650vh"
              title="agreement"
              frameBorder="0"
              allowFullScreen
              loading="lazy"
            />
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default CompanyProfile;
