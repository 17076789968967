import { ArrowDownward, Print } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useReactToPrint } from "react-to-print";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Invoice from "../OrderDetails/Invoice/Invoice";

const OrderDetails = ({}) => {
  const printRef = useRef(null);
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [orderInfo, setOrderInfo] = useState({});
  const [customOrderCode, setCustomOrderCode] = useState(null);

  useEffect(() => {
    console.log("testQf:", orderId);
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${orderId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOrderInfo(result[0]);
      });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleGeneratePdf = () => {
    profileRef.current.save();
  };
  const handleChange = (e) => {
    setCustomOrderCode(e.target.value);
  };
  const handleClick = () => {
    navigate("/orderdetails/" + customOrderCode);
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${customOrderCode}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOrderInfo(result[0]);
      });
  };

  return (
    <Box>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                paddingY: "60px",
                paddingX: "30px",
                height: { xs: "25vh", md: "60vh" },
              }}
            >
              <Stack
                alignItems="center"
                justifyContent={"center"}
                sx={{ height: "100%" }}
              >
                <Box sx={{ height: "10vh" }}>
                  <Stack spacing={0.5}>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "12pt", fontWeight: 500 }}
                    >
                      If you have invoice code:
                    </Typography>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"left"}
                    >
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"center"}
                        sx={{
                          borderRadius: "0px",
                          height: "40px",
                          width: "72px",
                          bgcolor: "#686261",
                          borderTopLeftRadius: "6px",
                          borderBottomLeftRadius: "6px",
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            fontWeight: 500,
                            color: "white",
                          }}
                        >
                          CODE
                        </Typography>
                      </Stack>

                      <OutlinedInput
                        placeholder="Please enter text"
                        value={customOrderCode}
                        name="order"
                        type="number"
                        size="small"
                        onChange={handleChange}
                        sx={{
                          borderRadius: "0px",
                          borderTopRightRadius: "6px",
                          borderBottomRightRadius: "6px",
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    direction={"row"}
                    justifyContent={"right"}
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      size="medium"
                      sx={{
                        bgcolor: "#686261",
                        borderRadius: "6px",
                        height: "25px",
                        marginLeft: "188px",
                        marginTop: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={handleClick}
                    >
                      Get Invoice
                    </Button>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>
          {orderInfo?.pr_poster ? (
            <Grid item xs={12} md={6}>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="right"
                alignItems={"center"}
                sx={{ height: "70px" }}
              >
                <Button
                  startIcon={<ArrowDownward />}
                  size="small"
                  // onClick={toPdf}
                  sx={{
                    paddingX: "10px",
                    color: "black",
                    bgcolor: "#e3dfdf",
                    fontSize: "9pt",
                    textTransform: "capitalize",
                    borderRadius: "30px",
                  }}
                  onClick={handleGeneratePdf}
                >
                  Download
                </Button>
              </Stack>
              <PDFExport
                paperSize="A4"
                // margin="0.5cm"
                orientation="0"
                // scale={0.8}
                fileName={"alqamaralzhabi_receipt-" + orderId}
                ref={profileRef}
              >
                <Box
                  sx={{ marginTop: "-30px", marginBottom: "0px" }}
                  ref={printRef}
                >
                  {orderInfo.order_id && <Invoice orderInfo={orderInfo} />}
                </Box>
              </PDFExport>
            </Grid>
          ) : (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "14pt", fontWeight: 600 }}
              >
                No Invoice found
              </Typography>
            </Stack>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default OrderDetails;
