import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box, IconButton, Modal, Rating, Stack, Toolbar } from "@mui/material";
import {
  AddShoppingCart,
  BrandingWatermark,
  ShoppingCart,
  Star,
  ZoomIn,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";

import { useEffect } from "react";

const ProductCard = (props) => {
  const { data } = props;
  console.log("dsdsadadsata:", data);
  const {
    category,
    title,
    pic_url,
    brand,
    details,
    offer_price,
    price,
    regular_price,
    currency,
    discount,
    pro_refer,
    poster,
  } = data;
  console.log("dsasaata:", data);
  const [open, setOpen] = useState(false);
  const [productDetails, setProductDetails] = useState({});
  console.log("productDetails:", productDetails);
  const [hoverOnPic, sethoverOnPic] = useState(0);
  const [hoverOnCard, sethoverOnCard] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  let count = 0;

  const navigate = useNavigate();

  const { addItem } = useCart();

  const addToCart = (data) => {
    console.log(data);
    addItem(data);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Card
      sx={{
        width: { xs: "95%", md: "95%" },
        height: "auto",
        marginLeft: { xs: "4px", sm: "6px", md: "8px" },
        cursor: "pointer",
        boxSizing: "border-box",
        // padding: "5px",
        position: "relative",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
      onMouseEnter={() => {
        sethoverOnCard(1);
      }}
      onMouseLeave={() => {
        sethoverOnCard(0);
      }}
    >
      {/* <Link to={"/productdetails"}> */}
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", margin: "auto", paddingTop: "10px" }}
      >
        {hoverOnPic === 0 ? (
          <Box
            component="img"
            sx={{
              // height: '100%',
              // width: "70%",
              height: "100px",
              objectFit: "cover",
            }}
            alt="The house from the offer."
            src={"http://saraelectro.alqamaralzhabi.com/admin_api/" + pic_url}
            onMouseEnter={() => {
              sethoverOnPic(1);
            }}
          />
        ) : (
          <Box
            component="img"
            sx={{
              // height: '100%',
              // width: "70%",
              height: "100px",
              objectFit: "cover",
            }}
            alt="The house from the offer."
            src={"http://saraelectro.alqamaralzhabi.com/admin_api/" + pic_url}
            onMouseLeave={() => {
              sethoverOnPic(0);
            }}
            // onClick={() => handlePDstorage(product_refer)}
          />
        )}
      </Stack>
      {/* </Link> */}
      <CardContent>
        <Stack spacing={1}>
          <Box sx={{ height: "30px" }}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"left"}
              alignItems={"center"}
              sx={{ marginTop: "15px" }}
            >
              <BrandingWatermark
                sx={{
                  fontSize: { md: "12pt" },
                }}
              />
              <Typography
                variant="p"
                sx={{
                  fontSize: { xs: "10pt", md: "11pt" },
                  fontWeight: 600,
                  color: "#22AB7D",
                }}
                onClick={() => {
                  navigate("/productdetails/" + pro_refer + "/" + poster);
                }}
              >
                {brand}
              </Typography>
            </Stack>
          </Box>

          <Stack
            direction={"column"}
            justifyContent={"left"}
            alignItems="flex-start"
            sx={{}}
          >
            <Box
              sx={{ height: "70px" }}
              onClick={() => {
                navigate("/productdetails/" + pro_refer + "/" + poster);
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 400 }}
              >
                {title}
              </Typography>
            </Box>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent={"left"}
              alignItems="center"
              sx={{ marginTop: "10px" }}
            >
              <Typography
                variant="p"
                sx={{
                  textDecoration: "line-through",
                  fontSize: "10pt",
                  color: "darkred",
                  fontWeight: 400,
                }}
              >
                {currency} {regular_price}
              </Typography>
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 700, color: "darkgreen" }}
              >
                {currency} {price}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingX: "15px",
          paddingBottom: "10px",
          marginTop: "-2px",
          boxSizing: "border-box",
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{
            width: "110px",
            minWidth: "43px",
            height: "35px",
            padding: "0px",
            color: "black",
            backgroundColor: "white",
            fontSize: "9pt",
            textTransform: "capitalize",
            boxShadow: "inset 0 0 0 0 #1fb5b5",
            transition: "ease-out .3s",
            borderRadius: "30px",
            boxSizing: "border-box",
            "&:hover": {
              color: "white",
              border: "1px solid white",
              backgroundColor: "white",
              boxShadow: "inset 110px 0 0 0 #1fb5b5",
            },
            // paddingY: "2px",
          }}
          startIcon={<ShoppingCart />}
          // onClick={() => addToCart(data)}
          onClick={() => addToCart(data)}
        >
          Add to cart
        </Button>

        <Box sx={{ width: "30%" }}>
          <Button
            // color="primary"
            variant="outlined"
            onClick={() => {
              navigate("/productdetails/" + pro_refer + "/" + poster);
            }}
            sx={{
              boxSizing: "border-box",
              boxShadow: "inset 0 0 0 0 #1fb5b5",
              transition: "ease-out .5s",
              borderRadius: "30px",
              "&:hover": {
                color: "white",
                border: "1px solid white",
                backgroundColor: "#1fb5b5",
                boxShadow: "inset 110px 0 0 0 #1fb5b5",
              },
            }}
          >
            <ZoomIn />
          </Button>
        </Box>
      </Stack>
      <Stack
        bgcolor="#f23534"
        sx={{
          width: "50px",
          height: "50px",
          padding: "10px 0px 0px 20px",
          borderRadius: "22%",
          position: "absolute",
          top: "-12px",
          left: "-22px",
        }}
        justifyContent="center"
        alignItems={"center"}
      >
        <Typography
          variant="caption"
          sx={{ fontSize: { xs: "7pt", sm: "8pt" }, color: "white" }}
        >
          {discount ? discount + "%" : "10%"}
        </Typography>
        <Typography
          variant="caption"
          sx={{ fontSize: { xs: "7pt", sm: "8pt" }, color: "white" }}
        >
          OFF
        </Typography>
      </Stack>
    </Card>
  );
};

export default ProductCard;
