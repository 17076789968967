import { Box } from "@mui/material";
import React from "react";
import OrderDetails from "../components/Booknow Component/Components/OrderDetails/OrderDetails";

const OrderDetailsPage = () => {
  return (
    <Box mb={10}>
      <OrderDetails />
    </Box>
  );
};

export default OrderDetailsPage;
