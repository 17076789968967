import React, { useEffect, useState } from "react";
import {
  AddCircle,
  AddShoppingCart,
  BrandingWatermark,
  Category,
  Close,
  FeaturedPlayListOutlined,
  Lock,
  RemoveCircle,
  ShoppingBasket,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
import { useContext } from "react";
import { DPproductContext } from "../../App";
import "./ProductOverview.css";

const ProductOveview = (props) => {
  const { productDetails, setProductDetails, refer } = props;
  const {
    id,
    brand,
    category,
    currency,
    details,
    offer_price,
    pic_url,
    quantity,
    regular_price,
    title,
    price,
    pro_refer,
    pr_specification,
    pr_features,
  } = productDetails;
  console.log("productDetails:", productDetails);
  console.log("detailsrty:", details?.split("•\t"));

  const {
    isEmpty,
    totalItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
    addItem,
  } = useCart();
  const navigate = useNavigate();
  const [DPproduct, setDPproduct] = useContext(DPproductContext);
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [render, setRender] = useState(false);
  const [ads, setAds] = useState({});
  const [selectColor, setSelectColor] = useState(0);
  console.log("selectColor:", selectColor);
  const [hoverColor, setHoverColor] = useState("");
  console.log("hoverColor:", hoverColor);
  const [selectFromPic, setSelectFromPic] = useState("");
  console.log("selectFromPic:", selectFromPic);
  const [hoverPic, setHoverPic] = useState("");
  const [selectVariation, setSelectVariation] = useState(0);
  console.log("selectVariation:", selectVariation);
  const [allPics, setAllPics] = useState([]);
  console.log("allPics:", allPics);
  const [allVariation, setAllVariation] = useState([]);
  console.log("allVariation:", allVariation);

  const addToCart = (data) => {
    console.log(data);
    addItem(data);
    console.log("data passed", data);
  };
  const updateProductPrice = (offer, reg) => {
    console.log("productDetailsInside:", productDetails);

    let newProductInfo = productDetails;
    newProductInfo.regular_price = reg;
    newProductInfo.offer_price = offer;
    newProductInfo.price = offer;
    setProductDetails(newProductInfo);
  };
  const addSubPrice = (pprice) => {
    console.log("productDetailsInside:", productDetails);
    console.log("productDetailsInside:", pprice);

    let newProductInfo = productDetails;
    newProductInfo.regular_price =
      parseFloat(regular_price) + parseFloat(pprice);
    newProductInfo.offer_price = parseFloat(offer_price) + parseFloat(pprice);
    newProductInfo.price = parseFloat(price) + parseFloat(pprice);
    setProductDetails(newProductInfo);
    setRender(!render);
  };

  useEffect(() => {
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/product_sponsored.php?category=${category}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtrttc:", result);
        setAds(result?.[0]);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [category]);
  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/pr_variation_all.php?product_id=${pro_refer}&puser=${parsedObject?.SopnoID}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllVariation(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [pro_refer]);

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/pr_var_pic_all.php?product_id=${refer}&pr_variant=${selectVariation?.id}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllPics(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [refer, selectVariation?.id]);

  let actual_pic_url =
    "http://saraelectro.alqamaralzhabi.com/admin_api/" + pic_url;
  useEffect(() => {
    setCurrentQuantity(quantity);
  }, [quantity]);

  const reduceQuantity = () => {
    updateItemQuantity(id, parseFloat(quantity) - 1);
    let newCount = currentQuantity;
    newCount--;
    setCurrentQuantity(newCount);
  };
  const addQuantity = () => {
    updateItemQuantity(id, parseFloat(quantity) + 1);
    let newCount = currentQuantity;
    newCount++;
    setCurrentQuantity(newCount);
  };
  let existingItem = items?.find((element) => element.id == id);
  console.log(
    "existingItem:",
    allVariation?.find((element) => element?.id === selectVariation?.id)
  );

  // const obj = {
  //   userId: 1,
  //   id: 1,
  //   title:
  //     "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
  //   body: "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto",
  // };

  // const res = Object.keys(obj).join("\n") + Object.values(obj).join("\n");
  // console.log("res", res);

  return (
    <Box sx={{ marginTop: { xs: "16%", md: "0%" }, width: "100%" }}>
      <Paper
        sx={{ borderRadius: "0px", bgcolor: "#1a1818", position: "relative" }}
      >
        <Stack
          spacing={3}
          direction={"row"}
          justifyContent="center"
          alignItems={"center"}
          sx={{ paddingX: "50px", marginBottom: "30px" }}
        >
          <Box
            component={"img"}
            src={
              "http://saraelectro.alqamaralzhabi.com/admin_api/" + ads?.pic_url
            }
            sx={{ height: "50px", padding: "5px" }}
          />
          <Stack justifyContent={"center"} alignItems="flex-start">
            <Typography
              variant="p"
              sx={{ fontSize: "10pt", fontWeight: 600, color: "white" }}
            >
              {ads?.title}
            </Typography>
            <Typography
              variant="p"
              sx={{ fontSize: "8pt", fontWeight: 600, color: "white" }}
            >
              {ads?.details?.slice(0, 64)}...
            </Typography>
          </Stack>
          <Stack>
            <Typography
              variant="p"
              sx={{
                display: "inline",
                fontWeight: 600,
                color: "#c31212",
                textDecoration: "line-through",
              }}
            >
              {/* Regular price : {regular_price} {currency} */}
              Regular price :{" "}
              {/* {existingItem?.id
                ? parseFloat(existingItem?.regular_price) *
                  existingItem?.quantity
                : regular_price}{" "} */}
              {selectVariation?.id
                ? selectVariation?.regular_price
                : regular_price}
              {currency}
            </Typography>
            <Typography
              variant="p"
              sx={{
                display: "inline",
                fontWeight: 600,
                color: "gold",
              }}
            >
              Offer price :{" "}
              {/* {existingItem?.id
                ? parseFloat(existingItem?.price) * existingItem?.quantity
                : offer_price}{" "} */}
              {selectVariation?.id ? selectVariation?.offer_price : offer_price}{" "}
              {currency}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          variant="p"
          sx={{
            fontSize: "8pt",
            fontWeight: 600,
            color: "white",
            position: "absolute",
            top: "3px",
            right: "3px",
          }}
        >
          Sponsored
        </Typography>
      </Paper>
      <Grid container spacing={1}>
        <Grid item xs={9.5}>
          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems="flex-start"
            justifyContent={"center"}
            sx={{}}
          >
            <Box
              sx={{ width: { xs: "100%", md: "50%" }, boxSizing: "border-box" }}
            >
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems="center"
                sx={{ width: "100%", height: "65vh" }}
                // sx={{ height: "85%", marginBottom: "4%" }}
              >
                <Stack sx={{ width: "15%" }}>
                  {allPics?.map((element) => (
                    <Box
                      component="img"
                      sx={{
                        padding: "3px",
                        // width: { xs: '50%', md: '92%'},
                        width: "60px",
                        height: "60px",
                        cursor: "pointer",
                        margin: "5px",
                        border: "1px solid #E6E6DF",
                      }}
                      alt={element?.pic_name}
                      src={
                        "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                        element?.pic_url
                      }
                      onClick={() => {
                        setSelectFromPic(element?.pic_url);
                      }}
                      onMouseEnter={() => {
                        setHoverPic(element?.pic_url);
                      }}
                      onMouseLeave={() => {
                        setHoverPic("");
                      }}
                    />
                  ))}
                </Stack>
                <Stack sx={{ width: "84%" }}>
                  <Box
                    component="img"
                    sx={{
                      padding: "15px",
                      width: { xs: "50%", md: "92%" },
                      height: "auto",
                      // height: { xs: "150px", md: "450px" },
                      margin: "3px",
                      // border: "1px solid #E6E6DF",
                    }}
                    alt="The house from the offer."
                    src={
                      hoverPic
                        ? "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                          hoverPic
                        : selectFromPic
                        ? "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                          selectFromPic
                        : selectVariation?.pic_url
                        ? "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                          selectVariation?.pic_url
                        : actual_pic_url
                    }
                  />
                </Stack>
              </Stack>
            </Box>
            <Box
              sx={{
                width: { xs: "100%", md: "50%" },
                padding: { xs: "20px", md: "2%" },
                // height: "420px",
                boxSizing: "border-box",
              }}
            >
              <Stack spacing={1} justifyContent="flex-start">
                {/* <Stack direction='row'>
                           <Rating name="read-only" value={4} readOnly />
                           <Typography variant='p' sx={{ display: 'inline' }}>(24 Ratings)</Typography>
                       </Stack> */}
                <Stack spacing={1}>
                  <Stack
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                    spacing={1}
                  >
                    {/* <Title sx={{ fontSize: "14pt", color: "#45b7bd" }} /> */}
                    <Typography
                      variant="p"
                      sx={{ fontSize: "18pt", fontWeight: 600 }}
                    >
                      {title}
                    </Typography>
                  </Stack>
                  <Divider />
                  <Stack>
                    <Typography
                      variant="p"
                      sx={{
                        display: "inline",
                        fontWeight: 600,
                        color: "darkred",
                        textDecoration: "line-through",
                      }}
                    >
                      {/* Regular price : {regular_price} {currency} */}
                      Regular price :{" "}
                      {/* {existingItem?.id
                ? parseFloat(existingItem?.regular_price) *
                  existingItem?.quantity
                : regular_price}{" "} */}
                      {selectVariation?.id
                        ? selectVariation?.regular_price
                        : regular_price}
                      {currency}
                    </Typography>
                    <Typography
                      variant="p"
                      sx={{
                        display: "inline",
                        fontWeight: 600,
                        color: "darkgreen",
                      }}
                    >
                      Offer price :{" "}
                      {/* {existingItem?.id
                ? parseFloat(existingItem?.price) * existingItem?.quantity
                : offer_price}{" "} */}
                      {selectVariation?.id
                        ? selectVariation?.offer_price
                        : offer_price}{" "}
                      {currency}
                    </Typography>
                  </Stack>
                  <Stack>
                    <Stack
                      direction={"row"}
                      justifyContent="left"
                      alignItems={"center"}
                      spacing={1}
                    >
                      <BrandingWatermark
                        sx={{ fontSize: "14pt", color: "#45b7bd" }}
                      />
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        Brand : {brand}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent="left"
                      alignItems={"center"}
                      spacing={1}
                    >
                      <Category sx={{ fontSize: "14pt", color: "#45b7bd" }} />
                      <Typography variant="p" sx={{ fontSize: "12pt" }}>
                        Category : {category}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack
                  spacing={2}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  {allVariation?.map((element) => (
                    <Box
                      sx={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        bgcolor: element?.color,
                        boxSizing: "border-box",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectVariation({
                          ...element,
                        });
                        setSelectFromPic("");
                      }}
                      onMouseEnter={() => {
                        setHoverColor(element?.color);
                        setHoverPic(element?.pic_url);
                      }}
                      onMouseLeave={() => {
                        setHoverColor("");
                        setHoverPic("");
                      }}
                      className={
                        hoverColor == element?.color
                          ? "selectedColor2"
                          : selectColor?.id == element?.id
                          ? "selectedColor"
                          : ""
                      }
                    ></Box>
                  ))}
                </Stack>
                <Stack
                  spacing={2}
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"left"}
                >
                  {allVariation?.map((element) => (
                    <Button
                      size="small"
                      color="success"
                      variant={
                        selectVariation?.id == element?.id
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => {
                        setSelectVariation({
                          // id: element?.id,
                          // offer_price: element?.offer_price,
                          // regular_price: element?.regular_price,
                          ...element,
                        });
                        setSelectFromPic("");
                        updateProductPrice(
                          element?.offer_price,
                          element?.regular_price
                        );
                      }}
                      onMouseEnter={() => {
                        setHoverColor(element?.color);
                        setHoverPic(element?.pic_url);
                      }}
                      onMouseLeave={() => {
                        setHoverColor("");
                        setHoverPic("");
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"left"}
                        spacing={2}
                      >
                        <Box
                          component={"img"}
                          src={
                            "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                            element?.pic_url
                          }
                          sx={{ height: "30px" }}
                        />
                        <Stack alignItems={"flex-start"}>
                          <Typography
                            variant="p"
                            sx={{ fontSize: "7pt", fontWeight: 700 }}
                          >
                            {element?.details}
                          </Typography>
                          <Typography
                            variant="p"
                            sx={{ fontSize: "7pt", fontWeight: 600 }}
                          >
                            {currency} {element?.offer_price}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Button>
                  ))}
                </Stack>
                <Stack sx={{ width: "100%" }} spacing={0.5}>
                  {pr_specification &&
                    JSON.parse(pr_specification)?.map((element) => (
                      <Stack
                        direction={"row"}
                        sx={{ width: "100%" }}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            fontWeight: 600,
                            width: "40%",
                          }}
                        >
                          {element?.sp_title}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "12pt", width: "60%" }}
                        >
                          {element?.sp_Details}
                        </Typography>
                      </Stack>
                    ))}
                </Stack>
                <Divider />
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems={"flex-start"}
                  spacing={1}
                >
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Features:
                    <ul style={{ marginTop: "0px", marginLeft: "-20px" }}>
                      {details
                        ?.split("•\t")
                        ?.map((element) => element && <li>{element}</li>)}
                    </ul>
                  </Typography>
                </Stack>
                <Stack>{pr_features}</Stack>
              </Stack>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={2.5}>
          <Paper sx={{ padding: "15px" }}>
            <Stack spacing={1}>
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 600 }}
              >
                {productDetails?.offer_price} {currency}
              </Typography>
              <Typography variant="p" sx={{ fontSize: "11pt" }}>
                Delivery within 7 days
              </Typography>
              {quantity >= 1 ? (
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 600, color: "green" }}
                >
                  In Stock
                </Typography>
              ) : (
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 600, color: "darkred" }}
                >
                  Out of Stock
                </Typography>
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="left"
                sx={{}}
                spacing={1}
              >
                <Typography variant="p" sx={{ fontWeight: 600 }}>
                  Quantity:{" "}
                </Typography>
                <Stack
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                >
                  {existingItem?.id && (
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        updateItemQuantity(
                          id,
                          parseInt(existingItem?.quantity) - 1
                        );
                      }}
                      className="ms-2"
                      // sx={{ marginLeft: "20px" }}
                    >
                      <RemoveCircle />
                    </IconButton>
                  )}

                  <span
                    style={{
                      color: "#000",
                      padding: "2px 14px",
                      border: "3px solid #777",
                      borderRadius: "10px",
                    }}
                  >
                    {existingItem?.id ? existingItem?.quantity : 1}
                  </span>

                  {existingItem?.id && (
                    <IconButton
                      color="primary"
                      variant="contained"
                      // onClick={addQuantity}
                      onClick={() => {
                        updateItemQuantity(
                          id,
                          parseInt(existingItem?.quantity) + 1
                        );
                      }}
                      className="ms-2"
                    >
                      <AddCircle />
                    </IconButton>
                  )}
                </Stack>
              </Stack>

              <Stack spacing={1} alignItems="center" sx={{ paddingTop: "2%" }}>
                <Button
                  variant="contained"
                  startIcon={<AddShoppingCart />}
                  sx={{
                    width: "100%",
                    bgcolor: "black",
                    color: "white",
                    textTransform: "capitalize",
                    fontSize: "9pt",
                    "&:hover": { color: "white" },
                  }}
                  onClick={() => addToCart(productDetails)}
                >
                  Add to cart
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<ShoppingBasket />}
                  sx={{
                    width: "100%",
                    bgcolor: "white",
                    color: "black",
                    fontSize: "9pt",
                    textTransform: "capitalize",
                    "&:hover": { color: "black" },
                  }}
                  onClick={() => {
                    setDPproduct(productDetails);
                    navigate(
                      "/booknow/" +
                        offer_price +
                        "/" +
                        currentQuantity +
                        "/" +
                        (Math.floor(Math.random() * 1000000000000 + 1) * 5 +
                          Math.floor(Math.random() * 1000000000 + 5))
                    );
                  }}
                >
                  Direct Purchase
                </Button>
              </Stack>
              <Stack
                sx={{}}
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent={"left"}
              >
                <Lock sx={{ fontSize: "14pt" }} />
                <Typography variant="p" sx={{ fontSize: "11pt" }}>
                  Secure transaction
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={1}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", color: "gray" }}
                >
                  Ships form
                </Typography>
                <Typography variant="p" sx={{ fontSize: "10pt" }}>
                  http://saraelectro.alqamaralzhabi.com
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
                spacing={3.4}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", color: "gray" }}
                >
                  Sold by
                </Typography>
                <Typography variant="p" sx={{ fontSize: "10pt" }}>
                  http://saraelectro.alqamaralzhabi.com
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="left"
                alignItems={"flex-start"}
                spacing={3.9}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "10pt", color: "gray" }}
                >
                  Return
                </Typography>
                <Typography variant="p" sx={{ fontSize: "9pt" }}>
                  Eligible for Return, Refund or Replacement within 30 days of
                  receipt
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          {allVariation?.find((element) => element?.id === selectVariation?.id)
            ?.pr_service?.[0]?.id && (
            <Paper sx={{ padding: "15px", marginTop: "20px" }}>
              <Stack spacing={0.5}>
                {allVariation
                  ?.find((element) => element?.id === selectVariation?.id)
                  ?.pr_service?.map((element) => (
                    <Stack spacing={0.5}>
                      <Typography variant="p" sx={{ fontSize: "10pt" }}>
                        {element?.sub_title}
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "10pt",
                            color: "darkred",
                            fontWeight: 600,
                          }}
                        >
                          {element?.sub_price} {currency}
                        </Typography>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            fontSize: "7pt",
                            paddingY: "1px",
                            borderRadius: "30px",
                          }}
                          onClick={() => {
                            // addSubPrice(element?.sub_price);
                            element.id = Math.floor(
                              Math.random() * 10000000000000 + 969
                            );
                            addToCart(element);
                          }}
                        >
                          Add to cart
                        </Button>
                      </Stack>
                    </Stack>
                  ))}
              </Stack>
            </Paper>
          )}
        </Grid>
      </Grid>

      <Close
        onClick={props.handleClose}
        sx={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
      />
    </Box>
  );
};

export default ProductOveview;
