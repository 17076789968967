import {
  AttachMoney,
  BrandingWatermark,
  CheckCircleRounded,
  Comment,
  Favorite,
  LocalOffer,
  MenuBook,
  ModeComment,
  MoneyOff,
  Schedule,
  Star,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Rating,
  styled,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./ServiceCardItem.css";
const ProductCardFlat = (props) => {
  const navigate = useNavigate();
  const { data } = props;
  const {
    category,
    title,
    pic_url,
    brand,
    details,
    price,
    offer_price,
    regular_price,
    currency,
    pro_refer,
    poster,
  } = data;
  console.log("datasdsd:", data);

  let actual_pic_url =
    "http://saraelectro.alqamaralzhabi.com/admin_api/" + pic_url;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };
  return (
    <Card
      onClick={() => {
        navigate("/productdetails/" + pro_refer + "/" + poster);
      }}
      sx={{
        width: "100%",
        // height: { xs: "45vh", sm: "50vh", lg: "58vh" },
        height: "auto",
        "&:hover": {
          boxShadow: "5px 5px 15px grey;",
        },
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="service"
        sx={{ marginLeft: 1, marginTop: 1 }}
      >
        <Box sx={{ width: "30%", position: "relative" }}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{ height: "33vh" }}
          >
            <Box
              component="img"
              height="70%"
              src={actual_pic_url}
              alt={"Stylin"}
              sx={{ opacity: 1, borderTopLeftRadius: "20px" }}
            />
          </Stack>

          {true && (
            <Box
              sx={{
                position: "absolute",
                top: "4%",
                left: "2%",
                padding: "1% 2%",
                bgcolor: "black",
                borderRadius: "5px",
              }}
            >
              <Typography
                variant="p"
                sx={{ color: "white", fontSize: "10pt", fontWeight: 600 }}
              >
                Featured
              </Typography>
            </Box>
          )}
        </Box>

        <Stack sx={{ position: "relative", cursor: "pointer", width: "50%" }}>
          <Stack
            spacing={2}
            sx={{ paddingLeft: "7%", mt: "" }}
            justifyContent={"start"}
          >
            <Stack
              spacing={0}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              //   sx={{ height: "90px" }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
                sx={{ marginTop: "15px" }}
              >
                <BrandingWatermark
                  sx={{
                    fontSize: { md: "12pt" },
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "10pt", md: "11pt" },
                    fontWeight: 600,
                    color: "#22AB7D",
                  }}
                >
                  {brand}
                </Typography>
              </Stack>
              <Typography
                gutterBottom
                variant="p"
                color="black"
                sx={{ fontSize: { xs: "11pt", md: "13pt" }, fontWeight: 500 }}
                component="div"
              >
                {title}
              </Typography>

              <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"left"}
                alignItems={"center"}
                sx={{ marginTop: "5px" }}
              >
                <MenuBook
                  sx={{
                    fontSize: { md: "12pt" },
                  }}
                />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: { xs: "10pt", md: "9pt" },
                    fontWeight: 600,
                    color: "black",
                  }}
                >
                  {details.length >= 40
                    ? details.slice(0, 40) + "..."
                    : details}
                </Typography>
              </Stack>

              <Stack
                justifyContent="center"
                alignItems="flex-start"
                sx={{ marginTop: "10px" }}
              >
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                >
                  <AttachMoney sx={{ fontSize: "14pt", color: "darkgreen" }} />
                  <Typography
                    variant="p"
                    sx={{
                      color: "darkgreen",
                      ml: "2px",
                      fontSize: { xs: "7pt", md: "10pt" },
                      fontWeight: 600,
                    }}
                  >
                    Offer price : {currency} {price}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                >
                  <MoneyOff sx={{ fontSize: "14pt", color: "darkred" }} />
                  <Typography
                    variant="p"
                    sx={{
                      color: "darkred",
                      ml: "2px",
                      fontSize: { xs: "7pt", md: "10pt" },
                      fontWeight: 600,
                      textDecoration: "line-through",
                    }}
                  >
                    Regular price : {currency} {regular_price}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mt: "20px", width: "20%", borderLeft: "1px solid gray" }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: { xs: "11pt", md: "13pt" },
              fontWeight: 600,
              color: "black",
            }}
          >
            {currency} {price}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "30px",
              bgcolor: "#14506A",
              textTransform: "capitalize",
              "&:hover": {
                bgcolor: "#22AB7D",
              },
            }}
            onClick={() => {
              navigate("/productdetails/" + pro_refer + "/" + poster);
            }}
            size="small"
          >
            Details
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ProductCardFlat;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 9,
    top: 11,
    border: `3px solid ${theme.palette.background.paper}`,
    padding: "5px",
    borderRadius: "50%",
  },
}));
