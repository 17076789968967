import { RemoveShoppingCart } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import { BsEmojiFrown } from 'react-icons/bs';

const Empty = () => {
    return (
        <Stack spacing ={1} justifyContent='center' alignItems= 'center' sx={{height: '100%'}}>
            <RemoveShoppingCart sx={{fontSize: '100pt'}}/>
            <Typography variant='h4' sx={{fontSize: '20pt'}}>Your Cart is Empty </Typography>
            <Typography variant='h4' sx={{fontSize: '14pt', textAlign: 'center'}}>Looks like you have not added anything to your cart. Go ahead & explore top categories </Typography>
        </Stack>
    );
};

export default Empty;