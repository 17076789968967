import { Button, Modal, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";

const OrderConfirmationModal = ({ open, handleClose, style, orderId }) => {
  const navigate = useNavigate();
  const {
    isEmpty,
    items,
    totalItems,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  const handleConfirm = () => {
    emptyCart();
    navigate("/orderdetails/" + orderId);
  };
  return (
    <Box>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="p" sx={{ fontSize: "16pt", fontWeight: 700 }}>
              Order Confirmation
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ marginTop: "5px" }}
          >
            <Typography variant="p" sx={{ fontSize: "15pt" }}>
              Do you want to confirm the order
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ marginTop: "30px" }}
          >
            <Button
              variant="contained"
              sx={{
                // marginTop: "15px",
                width: "40%",
                borderRadius: "30px",
                bgcolor: "gray",
                fontSize: { xs: "8pt", md: "10pt" },
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#14506A",
                },
              }}
              size="small"
              onClick={handleClose}
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{
                // marginTop: "15px",
                width: "40%",
                borderRadius: "30px",
                bgcolor: "#22AB7D",
                fontSize: { xs: "8pt", md: "10pt" },
                textTransform: "capitalize",
                "&:hover": {
                  bgcolor: "#14506A",
                },
              }}
              size="small"
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderConfirmationModal;
