import { HighlightOff, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "./ProductUpdate.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import swal from "sweetalert";

const ProductUpdateForm = ({
  setAddNewBtn,
  setLeftBtn,
  refer,
  poster,
  setCategory,
}) => {
  const [productInfo, setProductInfo] = useState({});
  console.log("productInfodr:", productInfo);
  const [inputField, setInputField] = useState({});
  console.log("inputField:", inputField);
  const [pdfAlertMessage, setPdfAlertMessage] = useState("");
  const [attachment, setAttachment] = useState("");
  const [preview, setPreview] = useState("");
  const [milestoneFields, setMilestoneFields] = useState([{ id: 0 }]);

  const [proDesc, setProDesc] = useState("");
  console.log("proDesc:", proDesc);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    return setEditorState(editorState);
  };

  useEffect(() => {
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/product_details.php?pro_ref=${refer}&puser=${poster}`
    )
      .then((res) => res.json())
      .then((result) => {
        // setProductDetails(result[0]);
        console.log("resultwe:", result);
        const newProductInfo = productInfo;
        newProductInfo.brand = result[0]?.brand;
        newProductInfo.title = result[0]?.title;
        newProductInfo.offerPrice = result[0]?.offer_price;
        newProductInfo.regPrice = result[0]?.regular_price;
        newProductInfo.category = result[0]?.category;
        newProductInfo.currency = result[0]?.currency;
        newProductInfo.available_quantity = result[0]?.quantity;
        newProductInfo.feature = result[0]?.details;
        newProductInfo.specification = JSON.parse(result[0]?.pr_specification);
        setMilestoneFields(JSON.parse(result[0]?.pr_specification));
        // newProductInfo.pro_info = result[0]?.pr_features;
        newProductInfo.pro_pic =
          "http://saraelectro.alqamaralzhabi.com/admin_api/" +
          result[0]?.pic_url;
        setProductInfo({ ...newProductInfo });
        setCategory(result[0]?.category);
        setProDesc(result[0]?.pr_features);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [refer, poster]);

  const handleUpdate = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    productInfo.p_user = parsedObject?.SopnoID;
    let others = {
      product_id: refer,
      category: productInfo?.category,
      title: productInfo?.title,
      brand: productInfo?.brand,
      offerPrice: productInfo?.offerPrice,
      regularPrice: productInfo?.regPrice,
      feature: productInfo?.feature,
      currency: productInfo?.currency,
      specification: productInfo?.specification,
      available_quantity: productInfo?.available_quantity,
      pro_info: proDesc,
      p_user: parsedObject?.SopnoID,
    };
    const formData = new FormData();
    formData.append("newProduct", JSON.stringify(others));
    formData.append("pro_pic", productInfo.pro_pic);

    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/product_update.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.m_type) {
          swal({
            title: result?.m_result,
            text: result?.q_message,
            icon: result?.m_type,
            button: "Done",
          });
          setInputField({});
          setProductInfo({});
          setLeftBtn("all");
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const handleMilestoneFormChange = (index, event) => {
    if (event.target.name == "ms_date") {
      event.target.name.replace("/", "-");
      let data = [...milestoneFields];
      data[index][event.target.name] = event.target.value;
      data[index].id = index;
      setMilestoneFields(data);
      let newProductInfo = productInfo;
      newProductInfo.specification = milestoneFields;
      setProductInfo({ ...newProductInfo });
    } else {
      let data = [...milestoneFields];
      data[index][event.target.name] = event.target.value;
      data[index].id = index;
      setMilestoneFields(data);
      let newProductInfo = productInfo;
      newProductInfo.specification = milestoneFields;
      setProductInfo({ ...newProductInfo });
    }
  };
  const addMilestoneFields = () => {
    let id = Math.floor(Math.random() * 1000000000 + 1);
    let newfield = { id: id };
    setMilestoneFields([...milestoneFields, newfield]);
  };
  const handleDeleteMS = (id) => {
    let filteredField = milestoneFields.filter((element) => element.id != id);
    setMilestoneFields([...filteredField]);
    let newProductInfo = productInfo;
    newProductInfo.specification = filteredField;
    setProductInfo({ ...newProductInfo });
  };
  const handleAttachment = (e) => {
    if (
      e.target.files[0].type == "image/png" ||
      e.target.files[0].type == "image/jpeg"
    ) {
      // setAttachment(URL.createObjectURL(e.target.files[0]));
      console.log("eeerere:", e.target.files[0]);

      setAttachment(e.target.files[0]);
      let newInfo = productInfo;
      newInfo[e.target.name] = e.target.files[0];
      setProductInfo({ ...newInfo });
      setPdfAlertMessage("");

      const objectUrl = URL.createObjectURL(e.target.files[0]);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    } else {
      e.target.value = null;
      setPdfAlertMessage("This is not a valid file!");
    }
  };

  const handleAttachmentCancel = (name) => {
    let newInfo = productInfo;
    newInfo[name] = {};
    setProductInfo({ ...newInfo });
    setPreview("");
  };
  const clear = () => {
    setProductInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "regPrice") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "offerPrice") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }
    if (event.target.name === "category") {
      isFieldValid = true;
    }
    if (event.target.name === "currency") {
      isFieldValid = true;
    }
    if (event.target.name === "available_quantity") {
      isFieldValid = /[0-9]/.test(event.target.value);
    }

    if (event.target.name === "brand") {
      isFieldValid = /^\S+$/.test(event.target.value);
    }

    if (event.target.name === "feature") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newProductInfo = productInfo;
      newProductInfo[event.target.name] = event.target.value;
      setProductInfo({ ...newProductInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);

      const newProductInfo = { ...productInfo };
      newProductInfo.fieldErrorAlert = event.target.name;
      newProductInfo[event.target.name] = "";
      setProductInfo(newProductInfo);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Title
              </Typography>
              {inputField.fieldErrorAlert == "title" && (
                <small style={{ color: "#CB0101" }}>
                  *Number field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="title"
              size="small"
              value={productInfo?.title}
              placeholder="Enter title"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Category
              </Typography>
              {inputField.fieldErrorAlert === "category" && (
                <small style={{ color: "#CB0101" }}>*Select category</small>
              )}
            </Stack>
            {console.log("productInfoxxx:", productInfo?.category)}
            <Select
              // displayEmpty
              onChange={handleChange}
              size="small"
              name="category"
              value={productInfo?.category}
              // placeholder="Request"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              // inputProps={{ "aria-label": "Without label" }}
            >
              {/* {categoryList?.map((element, index) => (
                <MenuItem key={index} value={element.category_name}>
                  {element.category_name}
                </MenuItem>
              ))} */}
              <MenuItem value={"mobile"}>Mobile</MenuItem>
              <MenuItem value={"laptop"}>Laptop</MenuItem>
              <MenuItem value={"desktop"}>Desktop</MenuItem>
              <MenuItem value={"router"}>Router</MenuItem>
              <MenuItem value={"AccessPoint"}>Access point</MenuItem>
              <MenuItem value={"switch"}>Switch</MenuItem>
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Brand
              </Typography>
              {inputField.fieldErrorAlert === "brand" && (
                <small style={{ color: "#CB0101" }}>*Enter brand name</small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="brand"
              size="small"
              value={productInfo?.brand}
              placeholder="Enter brand"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Available Quantity
              </Typography>
              {inputField.fieldErrorAlert === "available_quantity" && (
                <small style={{ color: "#CB0101" }}>*Enter brand name</small>
              )}
            </Stack>

            <OutlinedInput
              type="number"
              name="available_quantity"
              size="small"
              value={productInfo?.available_quantity}
              placeholder="Enter available_quantity"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={4.5}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Offer price
              </Typography>
              {inputField.fieldErrorAlert === "offerPrice" && (
                <small style={{ color: "#CB0101" }}>*Offer price empty</small>
              )}
            </Stack>

            <OutlinedInput
              type="number"
              name="offerPrice"
              size="small"
              value={productInfo?.offerPrice}
              placeholder="Offer price"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={4.5}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Regular price
              </Typography>
              {inputField.fieldErrorAlert === "regPrice" && (
                <small style={{ color: "#CB0101" }}>*Regular price empty</small>
              )}
            </Stack>

            <OutlinedInput
              type="number"
              size="small"
              name="regPrice"
              value={productInfo?.regPrice}
              placeholder="Regular price"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              // value={videoUrl}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Currency
              </Typography>
              {inputField.fieldErrorAlert === "currency" && (
                <small style={{ color: "#CB0101" }}>*Select currency</small>
              )}
            </Stack>
            <Select
              onChange={handleChange}
              size="small"
              name="currency"
              value={productInfo?.currency}
              placeholder="Title"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"USD"}>USD</MenuItem>
              <MenuItem value={"BDT"}>BDT</MenuItem>
              <MenuItem value={"AED"}>AED</MenuItem>
            </Select>
          </Stack>
        </Grid>

        {milestoneFields?.map((input, index) => {
          return (
            <Grid item xs={12} key={index} mb={0}>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack spacing={0.5} sx={{ width: "49%" }}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "11pt",
                        fontWeight: 500,
                        // paddingBottom: "2px",
                      }}
                    >
                      Specification Title
                    </Typography>
                  </Stack>
                  <OutlinedInput
                    name="sp_title"
                    type="text"
                    size="small"
                    placeholder="Enter specification title"
                    value={input?.sp_title}
                    sx={{
                      marginTop: "7px",
                      width: "100%",
                      bgcolor: "#D4EAFF",
                      borderRadius: "6px",
                      fontSize: "10pt",
                    }}
                    onChange={(event) =>
                      handleMilestoneFormChange(index, event)
                    }
                  />
                </Stack>
                <Stack spacing={0} sx={{ width: "49%" }}>
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "11pt",
                        fontWeight: 500,
                      }}
                    >
                      Details
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        handleDeleteMS(input.id);
                      }}
                    >
                      <HighlightOff fontSize="inherit" />
                    </IconButton>

                    {/* {inputField.fieldErrorAlert === "budget" && (
                                <small style={{ color: "#CB0101" }}>
                                  *Enter milestone details
                                </small>
                              )} */}
                  </Stack>
                  {console.log("inputccc:", input?.sp_Details)}
                  <OutlinedInput
                    name="sp_Details"
                    type="text"
                    size="small"
                    placeholder="Enter specification details"
                    value={input?.sp_Details}
                    sx={{
                      width: "100%",
                      bgcolor: "#D4EAFF",
                      borderRadius: "6px",
                      fontSize: "10pt",
                    }}
                    onChange={(event) =>
                      handleMilestoneFormChange(index, event)
                    }
                  />
                </Stack>
              </Stack>
            </Grid>
          );
        })}
        <Button
          onClick={addMilestoneFields}
          size="small"
          sx={{
            marginLeft: "10px",
            marginTop: "0px",
            textTransform: "capitalize",
          }}
        >
          More specification
        </Button>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Feature
              </Typography>
              {inputField.fieldErrorAlert === "feature" && (
                <small style={{ color: "#CB0101" }}>
                  *Description is empty
                </small>
              )}
            </Stack>

            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter product features"
              // value={about}
              value={productInfo?.feature}
              name="feature"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",
                backgroundColor: "#D4EAFF",
                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Product Info
              </Typography>
              {inputField.fieldErrorAlert === "pro_info" && (
                <small style={{ color: "#CB0101" }}>
                  *Description is empty
                </small>
              )}
            </Stack>

            {/* <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter product info"
              // value={about}
              value={productInfo?.pro_info}
              name="pro_info"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",
                backgroundColor: "#D4EAFF",
                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            /> */}
          </Stack>
          {/* <Editor
            defaultContent={"Enter details"}
            tools={tools}
            onChange={onChangeText}
            contentStyle={{ height: 100 }}
          /> */}
          <Editor
            style={{ color: "red" }}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
            onChange={() => {
              setProDesc(
                draftToHtml(convertToRaw(editorState.getCurrentContent()))
              );
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Upload Documents (jpg/png/pdf file)
              </Typography>
              {pdfAlertMessage && (
                <small style={{ color: "#CB0101" }}>{pdfAlertMessage}</small>
              )}
            </Stack>
          </Stack>

          <Stack
            spacing={0.5}
            direction="row"
            justifyContent={"center"}
            alignItems="center"
            sx={{ width: "100%", height: "auto" }}
          >
            <Button component="label">
              <Stack
                alignItems={"center"}
                justifyContent="center"
                sx={{
                  width: "80px",
                  height: "80px",
                  border: ".5px solid gray",
                  borderRadius: "4px",
                }}
              >
                <Person sx={{ fontSize: "16pt" }} />
                <Typography
                  variant="p"
                  sx={{
                    fontSize: "11pt",
                    fontWeight: 700,
                    textTransform: "capitalize",
                  }}
                >
                  Pic
                </Typography>
              </Stack>
              <input
                type="file"
                hidden
                name="pro_pic"
                size="small"
                sx={{
                  width: "100%",
                  bgcolor: "#D4EAFF",
                  borderRadius: "6px",
                  fontSize: "9pt",
                }}
                onChange={handleAttachment}
              />
            </Button>

            {(preview || productInfo?.pro_pic) && (
              <Box
                component={"img"}
                src={preview ? preview : productInfo?.pro_pic}
                sx={{ width: "85px", borderRadius: "4px" }}
              />
            )}
          </Stack>
          <Stack spacing={0.5} sx={{ marginTop: "10px" }}>
            {productInfo?.pro_pic?.name && (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "9pt", fontWeight: 700 }}
                >
                  pro_pic - {productInfo?.pro_pic?.name}
                </Typography>

                <HighlightOff
                  sx={{ fontSize: "11pt", cursor: "pointer" }}
                  onClick={() => {
                    handleAttachmentCancel("pro_pic");
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {productInfo.currency &&
            productInfo.offerPrice &&
            productInfo.regPrice &&
            productInfo.title &&
            productInfo.brand &&
            productInfo.feature &&
            productInfo.category &&
            productInfo?.pro_pic ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleUpdate();
                  setAddNewBtn(false);
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Update
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductUpdateForm;
