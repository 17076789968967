import { Container } from "@mui/material";
import { Box, Stack } from "@mui/system";
import CartLeft from "./CartLeft";
import CartRight from "./CartRight";

const ShoppingCart = () => {
  return (
    <Box>
      <Container>
        <Stack
          spacing={2}
          direction={{ xs: "column", md: "row" }}
          sx={{ marginTop: { xs: "70px", md: "0px" } }}
        >
          <CartLeft />
          <CartRight />
        </Stack>
      </Container>
    </Box>
  );
};

export default ShoppingCart;
