import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Box, Stack } from "@mui/system";
import { KeyboardArrowDown } from "@mui/icons-material";
import "./CategoryList.css";
const list = [
  { title: "Mobile", id: 1, value: "mobile" },
  { title: "Laptop", id: 2, value: "laptop" },
  { title: "Desktop", id: 3, value: "desktop" },
  { title: "Router", id: 4, value: "router" },
  { title: "Access Point", id: 5, value: "accessPoint" },
  { title: "Switch", id: 6, value: "switch" },
];

const CategoryList = ({ setProductType }) => {
  const focus = useRef("");
  const [toggle, setToggle] = useState(true);
  const [categoryHeight, setCategoryHeight] = useState("");
  const [categoryListData, setCategoryListData] = useState(list);
  console.log("categoryListData:", categoryListData);

  // useEffect(() => {
  //   fetch(serviceCategoryApi)
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setCategoryListData(result);
  //     })
  //     .catch((error) => console.log("ServiceApiError=> ", error));
  // }, []);
  useEffect(() => {
    handleCategoryHeight(focus.current.offsetHeight);
  }, [categoryListData[0]]);

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleToggle = (event) => {
    setToggle(!toggle);
  };
  console.log("categoriCategoryHeight:", categoryHeight);

  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
          Categories
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            handleToggle();
          }}
        >
          <KeyboardArrowDown
            fontSize="inherit"
            color="black"
            sx={{
              transform: toggle === false ? "rotate(-180deg)" : "",
              transition: "transform 1s",
            }}
          />
        </IconButton>
      </Stack>
      <Box
        className={toggle === false ? "lowCategoryHeight" : ""}
        sx={{
          height:
            categoryHeight && toggle ? `${categoryHeight}px !important` : "0px",
          overflow: "hidden",
          transition: "height 1s",
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }} ref={focus}>
          {categoryListData?.map((value) => (
            <ListItem
              onClick={() => {
                setProductType(value.value);
              }}
              key={value?.title}
              disableGutters
              sx={{ padding: 0, cursor: "pointer" }}
              secondaryAction={
                <IconButton
                  aria-label="comment"
                  sx={{
                    bgcolor: "#60C2A1",
                    color: "white",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Typography variant="p" sx={{ fontSize: "6pt" }}>
                    {value?.category_listing}
                  </Typography>
                </IconButton>
              }
            >
              <ListItemText primary={`${value.title}`} sx={{}} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CategoryList;
