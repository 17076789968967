import {
  CancelOutlined,
  FavoriteBorderOutlined,
  Menu,
  Person,
  ShoppingBagOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/Al-Qamar-Al-Zhabi.png";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useCart } from "react-use-cart";

const drawerWidth = 240;
const navItems = ["Home", "About", "Products", "Contact", "Organization"];

const Header = (props) => {
  let count = 0;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [srcProductName, setSrcProductName] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [updateCart, setUpdateCart] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!updateCart) {
      setUpdateCart([]);
    } else {
      setUpdateCart(JSON.parse(localStorage.getItem("cart")));
    }
  }, []);

  const handleSearchFieldChange = (event) => {
    setSrcProductName(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setProductCategory(event.target.value);
  };

  const handleClick = (path) => {
    if (path === "Home") {
      navigate("/");
    } else if (path === "About") {
      navigate("/about");
    } else if (path === "Products") {
      navigate("/products");
    } else if (path === "Contact") {
      navigate("/contact");
    } else if (path === "Organization") {
      navigate("/organization");
    }
  };

  function openMyWindow() {
    window.open(
      "http://trustseal.enamad.ir/Verify.aspx?id=15288&p=nbpdyncrwkynaqgwaqgw",
      "Popup",
      "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30"
    );
  }

  function handleClient() {
    window.open("https://user.http://saraelectro.alqamaralzhabi.com/");
  }

  let reduceWork = true;
  let subTotal = 0;
  if (!updateCart) {
    reduceWork = false;
  } else {
    subTotal = updateCart.reduce(
      (total, product) =>
        total + parseFloat(product.count) * parseFloat(product.product_price),
      0
    );
  }

  // const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Al Qamar Al Zhabi
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "left" }}>
              <ListItemText
                primary={item}
                onClick={() => {
                  handleClick(item);
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    // window !== undefined ? () => window().document.body : undefined;

    console.log(updateCart);
  const {
    isEmpty,
    totalItems,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  console.log(items);
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ bgcolor: "white" }}>
        <Container>
          <Toolbar sx={{ justifyContent: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <Menu sx={{ color: "black" }} />
            </IconButton>
            <Box
              component="img"
              sx={{
                marginLeft: "-30px",
                width: 300,
                // maxHeight: { xs: 233, md: 167 },
                // maxWidth: { xs: 350, md: 250 },
                display: { xs: "none", sm: "none", md: "block" },
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
              alt="The house from the offer."
              src={logo}
            />
            <Box
              sx={{
                // width: "60%",
                width: { sm: "80%", md: "75%" },
                display: { xs: "none", sm: "block" },
              }}
            >
              <Toolbar sx={{ padding: "0 12px" }}>
                <Toolbar sx={{ width: "100%" }}>
                  <OutlinedInput
                    onChange={handleSearchFieldChange}
                    placeholder="Search for products"
                    size="small"
                    sx={{
                      width: "70%",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />

                  <Select
                    sx={{
                      width: "25%",
                      marginLeft: "-1px",
                      height: "-1px 0",
                      borderRadius: 0,
                    }}
                    size="small"
                    value={productCategory}
                    onChange={handleCategoryChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <Typography variant="p" sx={{ fontSize: "10pt" }}>
                        All Categories
                      </Typography>
                    </MenuItem>
                    <MenuItem value={"mobile"}>Mobile</MenuItem>
                    <MenuItem value={"laptop"}>Laptop</MenuItem>
                    <MenuItem value={"desktop"}>Desktop </MenuItem>
                    <MenuItem value={"router"}>Router</MenuItem>
                    <MenuItem value={"AccessPoint"}>Access Point</MenuItem>
                    <MenuItem value={"switch"}>Switch</MenuItem>
                  </Select>
                  <Button
                    sx={{
                      border: "1px solid #dee2e6",
                      width: "7%",
                      padding: "0.43rem 0",
                      marginLeft: "-1px",
                      borderRadius: 0,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                    onClick={() => {
                      navigate(
                        "/search/" + productCategory + "/" + srcProductName
                      );
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Toolbar>
              </Toolbar>
            </Box>

            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
              sx={{
                display: { xs: "none", sm: "none", md: "none", lg: "block" },
                width: "20%",
              }}
            >
              {/* <Box></Box> */}
              <Stack sx={{ width: "60%" }}>
                <Typography
                  variant="p"
                  sx={{ color: "black", fontSize: "9pt" }}
                >
                  CALL US NOW
                </Typography>
                <Typography
                  variant="p"
                  sx={{ color: "black", fontSize: "9pt" }}
                >
                  +971558332996
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: { xs: "100%", md: "13%", sm: "13%" } }}
            >
              <Person
                onClick={() => {
                  handleClient();
                }}
                sx={{
                  color: "black",
                  fontSize: "22pt",
                  padding: "8px",
                  cursor: "pointer",
                }}
              />

              <StyledBadge badgeContent={isEmpty ? 0 : totalItems}>
                <div>
                  <ShoppingBagOutlined
                    sx={{
                      color: "black",
                      padding: "8px",
                      fontSize: "22pt",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={handleOpen}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Stack spacing={1} sx={{ width: "300px" }}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          padding: "10px",
                          bgcolor: "black",
                          color: "white",
                          width: "100%",
                          boxSizing: "border-box",
                        }}
                      >
                        <Typography variant="p">Items</Typography>
                        <Typography variant="p">View Cart</Typography>
                      </Stack>
                      {items?.map((product) => (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ position: "relative", width: "100%" }}
                        >
                          <Stack
                            sx={{
                              padding: "10px",
                              boxSizing: "border-box",
                              width: "80%",
                            }}
                          >
                            <Typography variant="body2">
                              {product.title}
                            </Typography>
                            <Typography variant="body2">
                              {product.quantity} X {parseFloat(product.price)}
                            </Typography>
                          </Stack>

                          <Box
                            component="img"
                            sx={{
                              width: "20%",
                              height: "auto",
                              boxSizing: "border-box",
                            }}
                            src={
                              "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                              product.pic_url
                            }
                          />
                          <CancelOutlined
                            onClick={() => removeItem(product.id)}
                            sx={{
                              color: "black",
                              position: "absolute",
                              top: 2,
                              right: 10,
                              zIndex: 5,

                              fontSize: "12pt",
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          />
                        </Stack>
                      ))}
                      <Divider />
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="p" sx={{ margin: "10px" }}>
                          Total
                        </Typography>

                        <Typography variant="p" sx={{ margin: "10px" }}>
                          {cartTotal} {items[0]?.currency}
                        </Typography>
                      </Stack>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleClose();
                            navigate("/shoppingCart");
                          }}
                          sx={{
                            bgcolor: "black",
                            color: "white",
                            width: "92%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          CheckOut
                        </Button>
                      </Box>

                      <Divider />
                    </Stack>
                  </Popover>
                </div>
              </StyledBadge>
            </Stack>
          </Toolbar>
          <Box
            sx={{
              width: "100%",
              // width: {sm: '80%', md: '60%',},
              display: { xs: "block", sm: "none", md: "none" },
            }}
          >
            <Toolbar sx={{ padding: "0" }}>
              <FormControl size="small" sx={{ width: "100%" }}>
                <Toolbar>
                  <OutlinedInput
                    onChange={handleSearchFieldChange}
                    placeholder="Search for products"
                    sx={{
                      width: "70%",
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                  />

                  <Select
                    sx={{
                      width: "30%",
                      marginLeft: "-1px",
                      height: "-1px 0",
                      borderRadius: 0,
                    }}
                    value={productCategory}
                    onChange={handleCategoryChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <Typography variant="p" sx={{ fontSize: "10pt" }}>
                        All Categories
                      </Typography>
                    </MenuItem>
                    <MenuItem value={""}>Mobile</MenuItem>
                    <MenuItem value={""}>Laptop</MenuItem>
                    <MenuItem value={""}>Desktop </MenuItem>
                    <MenuItem value={""}>Router</MenuItem>
                    <MenuItem value={""}>Access Point</MenuItem>
                    <MenuItem value={""}>Switch</MenuItem>
                  </Select>
                  <Button
                    sx={{
                      border: "1px solid #dee2e6",
                      width: "8%",
                      padding: "0.43rem 0",
                      marginLeft: "-1px",
                      borderRadius: 0,
                      borderTopRightRadius: 4,
                      borderBottomRightRadius: 4,
                    }}
                  >
                    <SearchIcon />
                  </Button>
                </Toolbar>
              </FormControl>
            </Toolbar>
          </Box>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 0 }}>
        <Toolbar />
      </Box>
    </Box>
  );
};

// Header.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };
export default Header;
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "black",
  },
}));
