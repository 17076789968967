import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  let newObject = localStorage.getItem("wmData");
  let parsedObject = JSON.parse(newObject);

  console.log("newObject:", parsedObject);
  console.log("newObject?.SopnoID:", parsedObject?.SopnoID);
  console.log("newObject?.MobileNumber :", parsedObject?.phone);
  return parsedObject?.SopnoID && parsedObject?.phone ? (
    children
  ) : (
    <Navigate to="/adminLogin" replace state={{ from: location }} />
  );
};
export default PrivateRoute;