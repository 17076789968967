import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./BookNow.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Add, Edit, Remove } from "@mui/icons-material";
import PaymentMethod from "./Components/PaymentMethod";
import Bkash from "../../images/bkash.png";
import Nagad from "../../images/nagad.png";
import BooknowTopSection from "./Components/BooknowTopSection";
import { useCart } from "react-use-cart";
import BooknowLeftItem from "./Components/BooknowLeftItem";
import OrderConfirmation from "./Components/OrderConfirmationModal";
import { useContext } from "react";
import { DPproductContext } from "../../App";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};
const Booknow = () => {
  const {
    items,
    totalItems,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();
  const { orderId, DPPprice, DPPquantity } = useParams();
  const [DPproduct, setDPproduct] = useContext(DPproductContext);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const [grandTotal, setGrandTotal] = useState(0);
  const [service, setService] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [gateway, setGeteway] = useState("bkash");
  const [activeTab, setActiveTab] = useState("");
  const [gatewayCharge, setGatewayCharge] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");

  const [fetchedPhone, setFetchedPhone] = useState("");
  const [fetchedEmail, setFetchedEmail] = useState("");
  const [fetchedName, setFetchedName] = useState("");
  const [fetchedCity, setFetchedCity] = useState("");
  const [fetchedAddress, setFetchedAddress] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [message, setMessage] = useState("");
  const [fetchedCountry, setFetchedCountry] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [fetchUser, setFetchUser] = useState([]);
  const [requestData, setRequestData] = useState("");

  const [userInfo, setUserInfo] = useState({});

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let horekrokom_userId = false;
  // let total = 0;
  // let howMany = 0;
  // for (const product of items) {
  //   howMany = howMany + product.quantity;
  //   total = total + product.price * product.quantity;
  // }
  let total;

  total = !DPPprice
    ? Math.round(cartTotal)
    : Math.round(
        parseFloat(DPproduct?.price) * parseFloat(DPproduct?.quantity)
      );
  console.log("cartTotal:", cartTotal);

  // total = items?.reduce(
  //   (total, product) =>
  //     total + product?.quantity * Math.round(parseFloat(product?.price)),
  //   0
  // );

  let vat = Math.round(parseFloat(total) * 0.15);
  const gatewayChargeList = [
    {
      type: "bkash",
      charge: 1.5,
      pic: Bkash,
    },
    {
      type: "nagad",
      charge: 1.2,
      pic: Nagad,
      api: "http://yeapbe.com/ghpay/nagad/index.js",
    },
  ];

  // infos outside of forms

  useEffect(() => {
    setGrandTotal(Math?.ceil((gatewayCharge / 100) * total + total + vat));
    let newUser = userInfo;
    newUser.totalAmount = grandTotal;
    setUserInfo(newUser);

    newUser.orderId = orderId;
    setUserInfo(newUser);

    newUser.paymentMethod = paymentMethod;
    setUserInfo(newUser);

    newUser.gatewayCharge = Math.ceil((gatewayCharge / 100) * total);
    setUserInfo(newUser);

    let cart = [];
    !DPPprice
      ? items?.map((element) => cart.push(element))
      : cart.push(DPproduct);
    newUser.cart = cart;
    console.log("carttt:", cart);

    const date = new Date();

    let orderDate =
      date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    newUser.orderDate = orderDate;
    setUserInfo(newUser);
  }, [quantity, gatewayCharge, grandTotal, orderId, paymentMethod, vat, total]);

  useEffect(() => {
    fetch("https://ghorami.com/profile/login/api_booking/country_list.php")
      .then((res) => res.json())
      .then((result) => {
        setCountryList(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("query", requestData);

    fetch("https://ghorami.com/profile/login/api_booking/fetch_user_info.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        setFetchUser(result);
        if (result?.p_phone) {
          setFetchedPhone(result?.p_phone);
          let newUser = userInfo;
          newUser.phone = result?.p_phone;
          setUserInfo(newUser);
        }
        if (result?.p_email) {
          setFetchedEmail(result?.p_email);
          let newUser = userInfo;
          newUser.email = result?.p_email;
          setUserInfo(newUser);
        }
        if (result?.p_nameS) {
          setFetchedName(result?.p_nameS);
          let newUser = userInfo;
          newUser.name = result?.p_nameS;
          setUserInfo(newUser);
        }
        if (result?.p_city) {
          setFetchedCity(result?.p_city);
          let newUser = userInfo;
          newUser.city = result?.p_city;
          setUserInfo(newUser);
        }
        if (result?.posterCountry) {
          setFetchedCountry(result?.posterCountry);
          let newUser = userInfo;
          newUser.country = result?.posterCountry;
          setUserInfo(newUser);
        }
        if (result?.p_address) {
          setFetchedAddress(result?.p_address);
          let newUser = userInfo;
          newUser.address = result?.p_address;
          setUserInfo(newUser);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [requestData]);

  const handleGatewayCharge = (charge, method) => {
    setGatewayCharge(charge);
    setPaymentMethod(method);
  };
  const handleActiveTab = (value) => {
    setActiveTab(value);
  };
  const handleOrder = (event) => {
    const initialValue = 0;
    let userId;
    // const subTotal = userInfo.cart?.reduce(
    //   (total, element) => total + parseFloat(element.price) * element.quantity,
    //   initialValue
    // );
    // if (!horekrokom_userId) {
    //   userId =
    //     Math.floor((Math.random() * 1000000000000000 + 1) * 1.3) +
    //     Math.floor(Math.random() * 1000000000000000 + 5);
    // }
    // console.log("subTotal:", subTotal);
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    const formData = new FormData();
    formData.append("p_user", parsedObject?.SopnoID);
    formData.append("order_id", orderId);
    formData.append("orderer_name", fetchedName);
    formData.append("orderer_phone", fetchedPhone);
    formData.append("orderer_email", fetchedEmail);
    formData.append("orderer_country", fetchedCountry);
    formData.append("orderer_city", fetchedCity);
    formData.append("orderer_address", fetchedAddress);
    formData.append("order_date", userInfo?.orderDate);
    formData.append("expected_delivery_date", deliveryDate);
    formData.append("orderer_message", message);
    formData.append("item", userInfo?.cart?.length);
    formData.append("quantity", !DPPprice ? totalItems : DPproduct?.quantity);
    formData.append("total_price", total);
    formData.append("vat", Math.round(parseFloat(total) * 0.15));
    formData.append("gateway_charge", userInfo?.gatewayCharge);
    formData.append("total_amount", userInfo?.totalAmount);
    formData.append("pay_method", userInfo?.paymentMethod);
    formData.append("order_list", JSON.stringify(userInfo?.cart));
    formData.append("ship_method", "homedelivery");

    fetch(
      "http://saraelectro.alqamaralzhabi.com/admin_api/booking_api/order_new.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => {
        res.json();
        handleOpen();
      })

      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.log("newOrderApiError=> ", error));
  };
  const handlePhoneChange = (event) => {
    setFetchedPhone(event.target.value);
    setRequestData(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };
  const handleEmailChange = (event) => {
    setFetchedEmail(event.target.value);
    setRequestData(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };
  const handleNameChange = (event) => {
    setFetchedName(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };
  const handleCountryChange = (event) => {
    setFetchedCountry(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };
  const handleCityChange = (event) => {
    setFetchedCity(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };
  const handleAddressChange = (event) => {
    setFetchedAddress(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };
  const handleDateChange = (event) => {
    setDeliveryDate(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };

  const handleMessage = (event) => {
    setMessage(event.target.value);
    let newUser = userInfo;
    newUser[event.target.name] = event.target.value;
    setUserInfo(newUser);
  };

  const addQuantity = () => {
    let q = quantity;
    setQuantity(q + 1);
  };
  const reduceQuantity = () => {
    let q = quantity;
    if (q <= 1) {
      setQuantity(1);
    } else {
      setQuantity(q - 1);
    }
  };

  return (
    <Container>
      <OrderConfirmation
        open={open}
        handleClose={handleClose}
        style={style}
        orderId={orderId}
      />
      <Grid container spacing={2} marginTop={3}>
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              padding: "15px",
              bgcolor: "#14506A",
              color: "white",
              height: "100%",
            }}
          >
            <Box sx={{ paddingBottom: "30px" }}>
              <Typography
                variant="p"
                sx={{ fontSize: "15pt", fontWeight: 700 }}
              >
                Oredred Item List
              </Typography>
            </Box>
            {!DPPprice &&
              !DPPquantity &&
              items?.map((element) => <BooknowLeftItem data={element} />)}

            {DPPprice && DPPquantity && <BooknowLeftItem data={DPproduct} />}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} sx={{ marginTop: "15px" }}>
          <Typography
            variant="p"
            sx={{ paddingLeft: "20px", fontSize: "15pt", fontWeight: 700 }}
          >
            Orderer Details
          </Typography>
          <Paper padding={3} sx={{ marginTop: "30px" }}>
            <Box className={toggle ? "" : "hideForm"}>
              <Grid container spacing={2} paddingLeft={5}>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Phone
                    </Typography>

                    <BootstrapInput
                      //   defaultValue="react-bootstrap"
                      value={fetchedPhone}
                      name="phone"
                      placeholder="Please enter text"
                      type="number"
                      size="small"
                      id="bootstrap-input"
                      onChange={handlePhoneChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Email
                    </Typography>
                    <BootstrapInput
                      name="email"
                      value={fetchedEmail}
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="email"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleEmailChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Name
                    </Typography>
                    <BootstrapInput
                      value={fetchedName}
                      name="name"
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="text"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleNameChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Country
                    </Typography>
                    <Select
                      size="small"
                      value={fetchedCountry}
                      name="country"
                      onChange={handleCountryChange}
                      displayEmpty
                      sx={{ width: "82%" }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {countryList?.map((element) => (
                        <MenuItem value={element?.country_name}>
                          {element?.country_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack>

                  {/* <BootstrapInput
                  //   defaultValue="react-bootstrap"
                  placeholder="Please enter text"
                  type="email"
                  size="small"
                  id="bootstrap-input"
                /> */}
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      City
                    </Typography>
                    <BootstrapInput
                      //   defaultValue="react-bootstrap"
                      name="city"
                      value={fetchedCity}
                      placeholder="Please enter text"
                      type="text"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleCityChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Address
                    </Typography>
                    <BootstrapInput
                      //   defaultValue="react-bootstrap"
                      name="address"
                      value={fetchedAddress}
                      placeholder="Please enter text"
                      type="text"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleAddressChange}
                    />
                  </Stack>
                </Grid>

                {/* <Grid item xs={6}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography variant="p" sx={{ fontSize: "12pt" }}>
                      Expected delivery Date
                    </Typography>
                    <BootstrapInput
                      name="deliveryDate"
                      value={deliveryDate}
                      sx={{ width: "800px" }}
                      //   defaultValue="react-bootstrap"
                      placeholder="Please enter text"
                      type="date"
                      size="small"
                      id="bootstrap-input"
                      onChange={handleDateChange}
                    />
                  </Stack>
                </Grid> */}
                {/* <Grid item xs={6}>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Total Pay
                  </Typography>
                  <BootstrapInput
                    value={service.service_price}
                    placeholder="Please enter text"
                    type="email"
                    size="small"
                    id="bootstrap-input"
                  />
                </Stack>
              </Grid> */}
                <Grid item xs={12}>
                  <Stack>
                    <TextareaAutosize
                      aria-label="minimum height"
                      name="message"
                      value={message}
                      minRows={3}
                      placeholder="Enter message"
                      id="bootstrap-input"
                      style={{ width: "90%" }}
                      onChange={handleMessage}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"right"}
                sx={{ width: "88%" }}
              >
                <Button
                  variant="contained"
                  sx={{ marginY: "20px" }}
                  onClick={() => {
                    setToggle(false);
                  }}
                >
                  Next
                </Button>
              </Stack>
            </Box>
            <Box className={toggle ? "hideForm" : ""}>
              <Box sx={{ padding: "20px" }}>
                <Paper sx={{ padding: "15px" }} elevation={5}>
                  <Stack spacing={1}>
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "14pt", fontWeight: 600 }}
                      >
                        {fetchedName}
                      </Typography>
                      <Button
                        startIcon={<Edit />}
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {
                          setToggle(true);
                        }}
                      >
                        Edit
                      </Button>
                    </Stack>
                    <Typography
                      variant="p"
                      sx={{ fontSize: "11pt", fontWeight: 500 }}
                    >
                      {fetchedAddress}, {fetchedCountry}
                    </Typography>
                    <Stack
                      direction={{ xs: "column", md: "row" }}
                      justifyContent="space-between"
                      alignItems={"center"}
                    >
                      <Stack justifyContent="center" alignItems={"flex-start"}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Phone:</strong> {fetchedPhone}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "11pt", fontWeight: 500 }}
                        >
                          <strong> Message: </strong>
                          {message}
                        </Typography>
                      </Stack>
                      <Stack justifyContent="center" alignItems={"flex-start"}>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Email: </strong>
                          {fetchedEmail}
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{ fontSize: "10pt", fontWeight: 500 }}
                        >
                          <strong> Delivery Date:</strong> {deliveryDate}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Paper>
                <PaymentMethod
                  gatewayCharge={gatewayChargeList}
                  handleGatewayCharge={handleGatewayCharge}
                  grandTotal={grandTotal}
                  service_refer={service.service_refer}
                  handleActiveTab={handleActiveTab}
                  activeTab={activeTab}
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper
            sx={{
              padding: "15px",
              bgcolor: "#14506A",
              color: "white",
              height: "100%",
              position: "relative",
            }}
          >
            <Stack justifyContent={"space-between"} alignItems="">
              <Typography
                variant="p"
                sx={{ fontSize: "16pt", fontWeight: 700 }}
              >
                Order Details
              </Typography>
              <Stack spacing={1} sx={{ marginTop: "20px" }}>
                {!DPPprice && !DPPquantity ? (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="p">Item</Typography>
                    <Typography variant="p">{items?.length}</Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="p">Item</Typography>
                    <Typography variant="p">{DPproduct?.quantity}</Typography>
                  </Stack>
                )}

                {!DPPprice && !DPPquantity ? (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="p">Quantity</Typography>
                    <Typography variant="p">{totalItems}</Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="p">Quantity</Typography>
                    <Typography variant="p">{DPproduct?.quantity}</Typography>
                  </Stack>
                )}

                {!DPPprice && !DPPquantity ? (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="p">Total Price</Typography>
                    <Typography variant="p">
                      {items[0]?.currency} {total}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack
                    direction={"row"}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="p">Total Price</Typography>
                    <Typography variant="p">
                      {items[0]?.currency} {DPproduct?.price}
                    </Typography>
                  </Stack>
                )}

                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="p">Tax or vat(15%)</Typography>
                  <Typography variant="p">
                    {items[0]?.currency} {vat}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="p">Gateway Charge</Typography>
                  <Typography variant="p">
                    {items[0]?.currency}{" "}
                    {Math?.ceil((gatewayCharge / 100) * total)}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ paddingBottom: { xs: "35px", md: "0px" } }}
                >
                  <Typography variant="p">Total Amount</Typography>
                  <Typography variant="p">
                    {items[0]?.currency} {grandTotal}
                  </Typography>
                </Stack>
              </Stack>
              {activeTab &&
                userInfo.phone &&
                userInfo.email &&
                userInfo.country &&
                userInfo.address &&
                userInfo.city && (
                  <Button
                    variant="outlined"
                    sx={{
                      boxSizing: "border-box",
                      border: "2px solid white",
                      color: "white",
                      position: "absolute",
                      width: "90%",
                      bottom: 20,
                      left: 15,
                      "&:hover": {
                        border: "2px solid white",
                      },
                    }}
                    onClick={() => {
                      // handleOrder();
                      handleOrder();
                    }}
                  >
                    Order Now
                  </Button>
                )}
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Booknow;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
