import { FavoriteBorder, HighlightOff } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FilledInput,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

const BooknowLeftItem = (props) => {
  const { quantity, title, pic_url, price, currency } = props.data;

  return (
    <Paper sx={{ padding: "10px", marginBottom: "10px", bgcolor: "#d0dadb" }}>
      <Stack direction="row" spacing={2} alignItems="flex-start">
        <Stack spacing={1} sx={{ width: "35%" }}>
          <Box
            component="img"
            sx={{
              width: "100%",
            }}
            alt="The house from the offer."
            src={"http://saraelectro.alqamaralzhabi.com/admin_api/" + pic_url}
          />{" "}
          <Typography variant="p" sx={{ fontSize: "9pt", textAlign: "center" }}>
            {currency} {parseFloat(price)} X {quantity}
          </Typography>
        </Stack>
        <Stack justifyContent="center" spacing={1} sx={{ width: "65%" }}>
          <Typography variant="p" sx={{ fontSize: "10pt", textAlign: "left" }}>
            {title}
          </Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default BooknowLeftItem;
