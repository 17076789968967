import { Box, Grid, Modal, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};
const SubModal = ({ open, handleClose, subState }) => {
  console.log("subStatesdsf:", subState);
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Product services
          </Typography>
          <Grid container spacing={2} mt={0}>
            {subState?.map((element) => (
              <>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="p" id="modal-modal-description">
                      Service Title : {element?.sub_title}
                    </Typography>
                    <Typography variant="p" id="modal-modal-description">
                      Service Price : {element?.sub_price}
                    </Typography>
                    <Typography variant="p" id="modal-modal-description">
                      Service Info : {element?.sub_info}
                    </Typography>
                  </Stack>
                </Grid>
              </>
            ))}{" "}
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default SubModal;
