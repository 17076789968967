import { Box } from "@mui/material";
import React from "react";
import ShoppingCart from "../components/ShoppingCart/ShoppingCart";

const ShoppingCartPage = () => {
  return (
    <Box mb={6}>
      <ShoppingCart />
    </Box>
  );
};

export default ShoppingCartPage;
