import { Cached, HighlightOff, ShoppingBag } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { bgcolor, Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCart } from "react-use-cart";
const CartRight = () => {
  const navigate = useNavigate();
  const [age, setAge] = useState("");
  const { items, cartTotal } = useCart();
  console.log("iteeeerrms:", items);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleClick = () => {
    navigate(
      "/booknow/" +
        (Math.floor(Math.random() * 1000000000000 + 1) * 5 +
          Math.floor(Math.random() * 1000000000 + 5))
    );
  };
  console.log(items);
  let total = 0;
  let shipping = 0;
  let quantity = 0;
  for (const product of items) {
    quantity = quantity + product.quantity;
    total = total + product.price * product.quantity;
    // shipping = shipping + product.shipping * product.shipping;
    // shipping = shipping + product.shipping * product.shipping;
  }
  const tax = parseFloat((total * 0.05).toFixed(2)); //5 % tax
  console.log(tax);
  const shippingTotal = parseFloat((items.length * 60).toFixed(2)); //60taka shipping cost
  console.log(shippingTotal);
  const grandTotal = total + tax + shippingTotal; //option 2

  return (
    <Stack
      sx={{ width: { xs: "100%", md: "33%" }, padding: { xs: 0, sm: "15px" } }}
    >
      <Stack
        spacing={1}
        sx={{
          // border: "1px solid gray",
          padding: "15px",
          marginTop: "-1px",
        }}
      >
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">Subtotal:</Typography>
            <Typography variant="h6">
              {items[0]?.currency} {cartTotal}
            </Typography>
          </Stack>
          <Divider />
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: "black" }}
            onClick={handleClick}
          >
            Proceed to checkout
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CartRight;
