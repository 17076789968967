import { Box, Button, OutlinedInput, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import bgLogo from "../images/Login.jpg";
import leftPic from "../images/leftPic.png";
import "../components/ProductUpload Components/login.css";
import { useLocation, useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location:", location);
  const [loginInfo, setLoginInfo] = useState({});
  const [preLoginInfo, setPreLoginInfo] = useState({});
  const [inputField, setInputField] = useState({});

  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "userId") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "password") {
      isFieldValid = /[0-9]|[a-z]|[A-Z]/.test(event.target.value);
      // /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,12}$/.test(
      //   event.target.value
      // );
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newregNumberInfo = preLoginInfo;
      newregNumberInfo[event.target.name] = event.target.value;
      setPreLoginInfo({ ...newregNumberInfo });
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };

  const handleLogin = () => {
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/admin_login.php?phone=${preLoginInfo?.userId}&pass=${preLoginInfo?.password}`
      // `http://whatsapp.ghorami.com/api/wm_login.php?customer_id=${preLoginInfo?.userId}&passkey=${preLoginInfo?.password}`
    )
      .then((res) => res.json())
      .then((result) => {
        setLoginInfo(result[0]);
        console.log("resultc:", result[0]);
        if (result[0]?.SopnoID) {
          localStorage.setItem("wmData", JSON.stringify(result[0]));
          navigate(location.state.from);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };
  return (
    <Stack sx={{ position: "relative" }}>
      <Box
        component={"img"}
        src={bgLogo}
        sx={{
          height: "100vh",
          width: "100%",
          objectFit: "cover",
          position: "absolute",
          top: 0,
          zIndex: -10,
        }}
      />
      {/* <Box
        sx={{
          height: "100vh",
          width: "100%",
          bgcolor: "rgba(0, 0, 0, 0.1)",
          position: "absolute",
          top: 0,
        }}
      /> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxSizing: "border-box",
        }}
      >
        <Stack
          alignItems={"center"}
          justifyContent="center"
          sx={{ width: "45%", height: "90vh", boxSizing: "border-box" }}
        >
          <Box
            component={"img"}
            src={leftPic}
            sx={{
              //   height: "50%",
              width: "60%",
            }}
          />
        </Stack>
        <Stack
          alignItems={"center"}
          justifyContent="center"
          sx={{
            width: "5%",
            height: "99.5vh",
            boxSizing: "border-box",
            transform: "rotate(10deg)",
          }}
        >
          <Box
            sx={{
              bgcolor: "red",
              width: "4px",
              height: "25%",
              borderTopRightRadius: "10px",
              borderTopLeftRadius: "10px",
            }}
          ></Box>
          <Box sx={{ bgcolor: "green", width: "4px", height: "25%" }}></Box>
          <Box sx={{ bgcolor: "yellow", width: "4px", height: "25%" }}></Box>
          <Box
            sx={{
              bgcolor: "blue",
              width: "4px",
              height: "25%",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          ></Box>
        </Stack>
        <Stack
          alignItems={"center"}
          justifyContent="center"
          sx={{
            width: "50%",
            height: "90vh",
            boxSizing: "border-box",
          }}
        >
          <Stack
            spacing={2}
            alignItems={"center"}
            justifyContent="center"
            sx={{
              height: "40vh",
              width: "60%",
              bgcolor: "white",
              border: "1px solid gray",
              boxShadow: "6px 5px 5px gray",
              borderRadius: "10px",
              boxSizing: "border-box",
            }}
          >
            <Typography variant="p" sx={{ fontSize: "18pt", fontWeight: 700 }}>
              Login
            </Typography>
            <OutlinedInput
              className="inputField"
              placeholder="User Id"
              name="userId"
              type="number"
              size="small"
              sx={{ width: "90%" }}
              onChange={handleChange}
            />
            <OutlinedInput
              placeholder="Password"
              name="password"
              size="small"
              type="password"
              sx={{ width: "90%" }}
              onChange={handleChange}
            />
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
              sx={{ width: "90%" }}
            >
              <Button>Forget Password</Button>
              <Button variant="outlined" onClick={handleLogin}>
                Login
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};

export default AdminLogin;
