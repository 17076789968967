import { Apps, Category, FormatListBulleted } from "@mui/icons-material";
import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CategoryList from "../components/ProductList Components/CategoryList";
import ProductCard from "../components/ProductList Components/ProductCard";
import ProductCardFlat from "../components/ProductList Components/ProductCardFlat";

const Search = () => {
  const { srcCategory, srcName } = useParams();
  const [searchedProduct, setSearchedProduct] = useState([]);
  const [productType, setProductType] = useState("");
  const [searchFound, setSearchFound] = useState(true);
  const [age, setAge] = useState("");
  const [viewMode, setViewMode] = useState("grid");

  useEffect(() => {
    const formData = new FormData();
    formData.append("query_text", srcName);
    formData.append("category", srcCategory);
    fetch(
      "http://saraelectro.alqamaralzhabi.com/admin_api/product_type_list_search.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulwewt:", result);
        setSearchedProduct(result);
        if (!result[0]?.id) {
          setSearchFound(false);
        } else {
          setSearchFound(true);
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, [srcName, srcCategory]);

  useEffect(() => {
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/product_type_list.php?category=${productType}`
    )
      .then((res) => res.json())
      .then((result) => {
        setSearchedProduct(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [productType]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box mb={6}>
      <Container sx={{ marginTop: "2%" }}>
        <Stack direction="row">
          <Paper
            elevation={3}
            sx={{
              width: "30%",
              boxSizing: "border-box",
              height: "102vh",
              padding: "20px",
            }}
          >
            <Stack>
              <CategoryList setProductType={setProductType} />
              <Divider light sx={{ marginBottom: "10px" }} />
            </Stack>
          </Paper>
          <Stack
            justifyContent="flex-start"
            sx={{ width: "70%", padding: "2%" }}
          >
            {" "}
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <Typography variant="p">Sort by:</Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>Default Sorting</em>
                    </MenuItem>
                    <MenuItem value={10}>Sort by popularity</MenuItem>
                    <MenuItem value={30}>Sort by newness</MenuItem>
                    <MenuItem value={30}>Sort by average rating</MenuItem>
                    <MenuItem value={30}>Sort by price: low to high</MenuItem>
                    <MenuItem value={30}>Sort by price: high to low</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: "5px" }}
                  onClick={() => {
                    setViewMode("grid");
                  }}
                >
                  <Apps />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setViewMode("list");
                  }}
                >
                  <FormatListBulleted />
                </Button>
              </Stack>
            </Stack>
            {searchFound ? (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container rowSpacing={{ xs: 2, md: 2 }}>
                  {viewMode === "list" &&
                    searchedProduct?.map((data) => (
                      <Grid item xs={2} sm={4} md={12} key={data.pro_pic}>
                        <ProductCardFlat data={data} />
                      </Grid>
                    ))}
                  {viewMode === "grid" &&
                    searchedProduct?.map((data) => (
                      <Grid item xs={2} sm={4} md={4} key={data.pro_pic}>
                        <ProductCard data={data} />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            ) : (
              <Stack
                sx={{ width: "100%", height: "50vh" }}
                alignItems="center"
                justifyContent={"center"}
              >
                <Typography variant="p" sx={{ textAlign: "center" }}>
                  Related product not found!
                </Typography>
              </Stack>
            )}
            {/* <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} /> */}
            <Pagination
              count={10}
              variant="outlined"
              color="primary"
              sx={{ margin: "auto" }}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Search;
