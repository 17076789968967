import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Mail,
  PhoneIphone,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "../../../images/Al-Qamar-Al-Zhabi.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: "black", zIndex: 5 }}>
      <Container>
        <Grid
          container
          rowSpacing={4}
          spacing={{ xs: 0, md: 2 }}
          sx={{ width: "100%", paddingY: "20px" }}
        >
          <Grid item xs={12} md={5.6} sx={{ order: 0 }}>
            <Stack spacing={2} sx={{ width: { xs: "100%", md: "80%" } }}>
              <Box
                component="img"
                sx={{
                  //   objectFit: "cover",

                  width: "250px",
                  height: "auto",
                }}
                alt="The house from the offer."
                src={logo}
              />
              <Typography
                variant="p"
                sx={{ color: "#b7b7b7", fontSize: "11pt" }}
              >
                Al QAMAR AL ZHABI General Trading LLC always tries to create
                great value and productivity gains for the clients by providing
                best quality solution to various business functions. We bring
                good experience in information technology, customer service,
                customer support and global operations by combining our
                strategic thinking with the best in Technology, People, and
                Process. We give our best while giving our services.
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={5}
            md={2.6}
            sx={{
              order: { xs: 2, md: 1 },
              paddingLeft: { xs: 0, md: "180px" },
              boxSizing: "border-box",
            }}
          >
            <Grid container spacing={{ xs: 2, md: 6 }}>
              <Grid item xs={12}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "white", fontSize: "14pt", fontWeight: 600 }}
                  >
                    Important links
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/contact");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "11pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Contact us
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/contact");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "11pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Service
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/about");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "11pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    About
                  </Typography>
                  <Typography
                    variant="p"
                    onClick={() => {
                      navigate("/products");
                    }}
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "11pt",
                      fontWeight: 500,
                      cursor: "pointer",
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    All products
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7} md={3.6} sx={{ order: { xs: 1, md: 2 } }}>
            <Grid container spacing={1}>
              <Grid item md={3} xs={0}></Grid>
              <Grid item xs={12} md={9}>
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "white", fontSize: "14pt", fontWeight: 600 }}
                  >
                    Contact us
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <LocationOn
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      Head Office:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="p"
                    sx={{
                      color: "#b7b7b7",
                      fontSize: "11pt",
                      fontWeight: 500,
                      "&:hover": {
                        color: "white",
                      },
                    }}
                  >
                    Shop No 2, FLoor G, Plot 0076 City Center, Sector Nakheel 1
                    Ajman UAE{" "}
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <PhoneIphone
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      Phone:
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      +971558332996
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Mail
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    >
                      info@http://saraelectro.alqamaralzhabi.com
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Facebook
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <YouTube
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Instagram
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <Twitter
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                    <LinkedIn
                      sx={{
                        color: "#b7b7b7",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                        "&:hover": {
                          color: "white",
                        },
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
