import { Box, Button, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AddVariationForm from "./Add Variation/AddVariationForm";
import VariationListTable from "./Add Variation/VariationListTable";
import ProductUpdateForm from "./ProductUpdateForm";

const ProductUpdate = () => {
  const { refer, poster } = useParams();
  const [category, setCategory] = useState();
  console.log("categosasary:", category);
  const [addNewVariation, setAddNewVariation] = useState("productUpdate");
  const [variationList, setVariationList] = useState(false);

  return (
    <Box mb={5}>
      <Container>
        <Stack>
          <Stack spacing={2} justifyContent={"center"} alignItems="center">
            <Stack
              direction={"row"}
              sx={{ width: "60%" }}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "12pt", fontWeight: 600 }}
              >
                Product Update
              </Typography>
              <Stack
                direction={"row"}
                justifyContent="right"
                alignItems={"center"}
                spacing={1}
              >
                {" "}
                <Button
                  size="small"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setVariationList(true);
                    setAddNewVariation("");
                  }}
                >
                  Variation List
                </Button>
                {addNewVariation == "productUpdate" && !variationList && (
                  <Button
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewVariation("addVariation");
                      setVariationList(false);
                    }}
                  >
                    Add new
                  </Button>
                )}
                {(addNewVariation == "addVariation" || variationList) && (
                  <Button
                    size="small"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      setAddNewVariation("productUpdate");
                      setVariationList(false);
                    }}
                  >
                    Back
                  </Button>
                )}
              </Stack>
            </Stack>
            <Box sx={{ width: "60%" }}>
              {addNewVariation == "productUpdate" && !variationList && (
                <ProductUpdateForm
                  refer={refer}
                  poster={poster}
                  setCategory={setCategory}
                />
              )}
              {addNewVariation == "addVariation" && !variationList && (
                <AddVariationForm
                  refer={refer}
                  poster={poster}
                  setAddNewVariation={setAddNewVariation}
                  category={category}
                />
              )}
            </Box>
            <Box sx={{ width: "60%" }}>
              {variationList && (
                <VariationListTable
                  refer={refer}
                  poster={poster}
                  // setCategory={setCategory}
                />
              )}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProductUpdate;
