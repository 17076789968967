import {
  Button,
  Container,
  Grid,
  Modal,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./grid1.css";

const ProductCategoryLaptop = (props) => {
  const navigate = useNavigate();

  const [productListData, setProductListData] = useState([]);
  console.log("productListData:", productListData);

  useEffect(() => {
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/product_type_list.php?category=laptop`
    )
      .then((res) => res.json())
      .then((result) => {
        setProductListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, []);
  console.log("productListDatahhhhhh:", productListData);

  let rightPicsList = productListData.slice(2, 4);
  return (
    <Container>
      <Stack justifyContent="center" alignItems="center" mb={2}>
        <Typography variant="p" sx={{ fontWeight: 500, fontSize: "28pt" }}>
          Shop Laptop
        </Typography>
        <Typography
          variant="p"
          sx={{ fontWeight: 600, fontSize: "13pt", color: "gray" }}
        >
          Find the laptop suited for you and your needs!
        </Typography>
      </Stack>

      <Grid
        container
        spacing={1}
        sx={{ height: "auto" }}
        rowSpacing={{ xs: 1, md: "auto" }}
      >
        <Grid item xs={12} md={6} sx={{}}>
          <Stack
            direction={"row"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              position: "relative",
              bgcolor: "#E6E6E6",
              height: "60vh",
            }}
            classname="pCon"
          >
            <Box
              component="img"
              sx={{
                height: "30vh",
                cursor: "pointer",
                transition: "all .5s",
                "&:hover": {
                  transform: "scale(1.2)",
                },
              }}
              alt="The house from the offer."
              src={
                "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                productListData[0]?.pic_url
              }
              onClick={() => {
                navigate(
                  "/productdetails/" +
                    productListData[0]?.pro_refer +
                    "/" +
                    productListData[0]?.poster
                );
              }}
            />
            <Stack
              direction={"row"}
              justifyContent="center"
              alignItems={"center"}
              sx={{
                position: "absolute",
                bottom: "35px",
                zIndex: 10,
                paddingX: "10px",
              }}
            >
              <Typography variant="p" sx={{ fontSize: "10pt" }}>
                {productListData[0]?.title}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{}}>
          {/* <Box sx={{ height: "100%" }}> */}

          <Grid container spacing={1} sx={{}}>
            <Grid item xs={12} sx={{ padding: 0 }}>
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems={"center"}
                sx={{
                  position: "relative",
                  bgcolor: "#E6E6E6",
                  height: { md: "29.4vh", xs: "60vh" },
                }}
                classname="pCon"
              >
                <Box
                  component="img"
                  sx={{
                    height: "18.8vh",
                    objectFit: "cover",
                    cursor: "pointer",
                    transition: "all .5s",
                    "&:hover": {
                      transform: "scale(1.2)",
                    },
                  }}
                  alt="The house from the offer."
                  src={
                    "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                    productListData[1]?.pic_url
                  }
                  onClick={() => {
                    navigate(
                      "/productdetails/" +
                        productListData[1]?.pro_refer +
                        "/" +
                        productListData[1]?.poster
                    );
                  }}
                />
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    position: "absolute",
                    bottom: "5px",
                    zIndex: 10,
                    paddingX: "15px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", textAlign: "center" }}
                  >
                    {productListData[1]?.title}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            {rightPicsList?.map((element) => (
              // let picList =  element.list.slice(1,4)
              <Grid item xs={12} md={6} sx={{ padding: 0 }}>
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    position: "relative",
                    bgcolor: "#E6E6E6",
                    height: { md: "29.4vh", xs: "60vh" },
                  }}
                  classname="pCon"
                >
                  <Box
                    component="img"
                    sx={{
                      height: "18.8vh",
                      objectFit: "cover",
                      cursor: "pointer",
                      transition: "all .5s",
                      "&:hover": {
                        transform: "scale(1.2)",
                      },
                    }}
                    alt="The house from the offer."
                    src={
                      "http://saraelectro.alqamaralzhabi.com/admin_api/" +
                      element?.pic_url
                    }
                    onClick={() => {
                      navigate(
                        "/productdetails/" +
                          element?.pro_refer +
                          "/" +
                          element?.poster
                      );
                    }}
                  />
                  <Stack
                    direction={"row"}
                    justifyContent="center"
                    alignItems={"center"}
                    sx={{
                      position: "absolute",
                      bottom: "5px",
                      zIndex: 10,
                      paddingX: "15px",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{ fontSize: "10pt", textAlign: "center" }}
                    >
                      {element?.title}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
          {/* </Box> */}
        </Grid>
      </Grid>

      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems={"center"}
        mt={2}
      >
        <Button
          variant="outlined"
          sx={{
            fontFamily: "Roboto Condensed",
            fontWeight: 400,
            fontSize: "15pt",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={() => {
            navigate("/products");
          }}
        >
          Load more...
        </Button>
      </Stack>
    </Container>
  );
};

export default ProductCategoryLaptop;
const style = {
  padding: 0,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
