import { PhonelinkSetup, RoomPreferences } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import CustomerInfoForm from "../components/ProductUpload Components/Order Components/CustomerInfoForm";
import NewOrderForm from "../components/ProductUpload Components/Order Components/NewOrderForm";
import CustomOrder from "../components/ProductUpload Components/Order Components/CustomOrder";
import ProductTableRapper from "../components/ProductUpload Components/Product upload/ProductTableRapper";
import UploadForm from "../components/ProductUpload Components/Product upload/UploadForm";
import "./css/LeftSide.css";
import OrderConfirmationModal from "../components/Booknow Component/Components/OrderConfirmationModal";
import ConfirmModal from "../components/ProductUpload Components/Order Components/ConfirmModal";
import OrderTableRapper from "../components/ProductUpload Components/Order Components/Order Table/OrderTableRapper";
import { useEffect } from "react";
import swal from "sweetalert";

const ProductUpload = () => {
  const [leftBtn, setLeftBtn] = useState("allOrder");
  const [newProBtn, setNewProBtn] = useState("");
  const [addedItems, setAddedItems] = useState([]);
  console.log("addedItems:", addedItems);
  const [newCustomerInfo, setnewCustomerInfo] = useState({});
  const [newCustomerFieldInfo, setNewCustomerFieldInfo] = useState({});
  const [newOrderToggle, setNewOrderToggle] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [shopInfo, setShopInfo] = useState({});
  const [render, setRender] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetch(
      `http://saraelectro.alqamaralzhabi.com//admin_api/booking_api/shop_details.php`
    )
      .then((res) => res.json())
      .then((result) => {
        setShopInfo(result[0]?.shop_info[0]);
        setOrderId(result[0]?.shop_info[0]?.invoi_sl);
      });
  }, [render]);
  // const orderId = "3520100855043";
  // const orderId =
  //   Math.floor(Math.random() * 1000000000000 + 1) * 5 +
  //   Math.floor(Math.random() * 1000000000 + 5);

  // if (addedItems?.length > 0) {
  //   const newArray = addedItems?.forEach((element, index) => {
  //     element.id = index;
  //     console.log("index:", index);
  //   });
  //   console.log("newArray:", newArray);
  // }
  const handleOrder = (event) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);

    // let newInfo = newCustomerInfo?.map(element, index);

    const formData = new FormData();
    formData.append("p_user", parsedObject?.SopnoID);
    formData.append("order_id", orderId);
    formData.append("orderer_name", newCustomerInfo?.name);
    formData.append("orderer_phone", newCustomerInfo?.mobile);
    formData.append(
      "orderer_email",
      newCustomerInfo?.email ? newCustomerInfo?.email : ""
    );
    formData.append("orderer_country", "AED");
    formData.append("orderer_city", "");
    formData.append("orderer_address", "");
    formData.append("order_date", newCustomerInfo?.date);
    formData.append("expected_delivery_date", "");
    formData.append("orderer_message", "");
    formData.append("item", addedItems?.length);
    formData.append(
      "quantity",
      addedItems?.reduce(
        (accumulator, currentValue) =>
          accumulator + parseFloat(currentValue?.quantity),
        0
      )
    );
    formData.append(
      "total_price",
      Math.round(
        addedItems?.reduce(
          (accumulator, currentValue) => accumulator + currentValue?.itemTotal,
          0
        ) * 0.05
      ) +
        addedItems?.reduce(
          (accumulator, currentValue) => accumulator + currentValue?.itemTotal,
          0
        )
    );
    formData.append(
      "vat",
      Math.round(
        addedItems?.reduce(
          (accumulator, currentValue) => accumulator + currentValue?.itemTotal,
          0
        ) * 0.05
      )
    );
    formData.append("gateway_charge", "0");
    formData.append(
      "total_amount",
      addedItems?.reduce(
        (accumulator, currentValue) => accumulator + currentValue?.itemTotal,
        0
      )
    );
    formData.append("pay_method", "CASH");
    formData.append("order_list", JSON.stringify(addedItems));
    formData.append("ship_method", "CashOn");

    fetch(
      "http://saraelectro.alqamaralzhabi.com/admin_api/booking_api/custom_order.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())

      .then((result) => {
        console.log(result);
        if (result?.m_result) {
          swal({
            title: result?.m_result,
            text: result?.q_message,
            icon: result?.m_type,
            button: "Done",
          });
          handleClose();
        }
        setNewCustomerFieldInfo({});
        setAddedItems([]);
        setRender(!render);
        setNewOrderToggle(false);
        setLeftBtn("allOrder");
      })
      .catch((error) => console.log("newOrderApiError=> ", error));
  };
  return (
    <Box mb={8}>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Box sx={{ padding: "20px" }}>
              <Stack
                spacing={1.5}
                justifyContent={"flex-start"}
                alignItems="flex-start"
                sx={{
                  maxHeight: "85vh",
                  overflow: "auto",
                  bgcolor: "#dbf0f7",
                  paddingBottom: "20px",
                }}
              >
                {" "}
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                  className={leftBtn === "allOrder" ? "active" : ""}
                  sx={{ marginTop: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setLeftBtn("allOrder");
                    setNewProBtn(false);
                  }}
                >
                  <PhonelinkSetup sx={{ fontSize: "14pt" }} />

                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 600 }}
                  >
                    All order
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                  className={leftBtn === "newOrder" ? "active" : ""}
                  sx={{ marginTop: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setLeftBtn("newOrder");
                    setNewProBtn(false);
                  }}
                >
                  <PhonelinkSetup sx={{ fontSize: "14pt" }} />

                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 600 }}
                  >
                    New order
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                  className={leftBtn === "all" ? "active" : ""}
                  sx={{ marginTop: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setLeftBtn("all");
                    setNewProBtn(false);
                  }}
                >
                  <RoomPreferences sx={{ fontSize: "14pt" }} />

                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 600 }}
                  >
                    All products
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent="left"
                  alignItems="center"
                  className={leftBtn === "new" ? "active" : ""}
                  sx={{ marginTop: "10px", cursor: "pointer" }}
                  onClick={() => {
                    setLeftBtn("new");
                    setNewProBtn(false);
                  }}
                >
                  <PhonelinkSetup sx={{ fontSize: "14pt" }} />

                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 600 }}
                  >
                    Add new Product
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={10}>
            {leftBtn === "all" && (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
                sx={{
                  width: "100%",
                }}
              >
                <ProductTableRapper />
              </Stack>
            )}
            {leftBtn === "new" && (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
                sx={{
                  width: "100%",
                }}
              >
                <Paper
                  sx={{
                    width: "75%",
                    height: "87vh",
                    overflowY: "auto",
                    paddingX: "25px",
                    paddingTop: "20px",
                    boxSizing: "border-box",
                  }}
                >
                  <UploadForm setLeftBtn={setLeftBtn} />
                </Paper>
              </Stack>
            )}
            {leftBtn === "allOrder" && (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
                sx={{
                  width: "100%",
                }}
              >
                <OrderTableRapper setRender={setRender} render={render} />
              </Stack>
            )}
            {leftBtn === "newOrder" && (
              <>
                <ConfirmModal
                  render={render}
                  setRender={setRender}
                  open={open}
                  handleClose={handleClose}
                  handleOrder={handleOrder}
                  setNewCustomerFieldInfo={setNewCustomerFieldInfo}
                />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Stack spacing={2}>
                      {newOrderToggle == false ? (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Paper
                            sx={{
                              width: "100%",
                              height: "auto",
                              overflowY: "auto",
                              paddingX: "25px",
                              paddingTop: "20px",
                              boxSizing: "border-box",
                            }}
                          >
                            <>
                              <CustomerInfoForm
                                newCustomerInfo={newCustomerInfo}
                                setnewCustomerInfo={setnewCustomerInfo}
                                newCustomerFieldInfo={newCustomerFieldInfo}
                                setNewCustomerFieldInfo={
                                  setNewCustomerFieldInfo
                                }
                                setLeftBtn={setLeftBtn}
                                setNewOrderToggle={setNewOrderToggle}
                              />
                            </>
                          </Paper>
                        </Stack>
                      ) : (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Paper
                            sx={{
                              width: "100%",
                              height: "auto",
                              overflowY: "auto",
                              paddingX: "25px",
                              paddingTop: "20px",
                              boxSizing: "border-box",
                            }}
                          >
                            <>
                              <NewOrderForm
                                setLeftBtn={setLeftBtn}
                                setNewOrderToggle={setNewOrderToggle}
                                addedItems={addedItems}
                                setAddedItems={setAddedItems}
                              />
                            </>
                          </Paper>
                        </Stack>
                      )}
                      {addedItems?.length > 0 && (
                        <Button
                          variant="contained"
                          color="error"
                          sx={{ marginTop: "20px" }}
                          onClick={handleOpen}
                        >
                          Complete Order
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={8}>
                    <CustomOrder
                      shopInfo={shopInfo}
                      orderId={orderId}
                      setOrderId={setOrderId}
                      newCustomerFieldInfo={newCustomerFieldInfo}
                      newOrderToggle={newOrderToggle}
                      setNewOrderToggle={setNewOrderToggle}
                      addedItems={addedItems}
                      setAddedItems={setAddedItems}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductUpload;
