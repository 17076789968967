import {
  Box,
  Button,
  Container,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ConfirmModal from "../Order Components/ConfirmModal";
import UpdateConfirmModal from "./UpdateConfirmModal";
import UpdateCustomerInfo from "./UpdateCustomerInfo";

const UpdateOrder = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [cart, setCart] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({});
  const [btn, setBtn] = useState(true);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("cart:", cart);
  console.log("customerInfo:", customerInfo);

  useEffect(() => {
    console.log("testQf:", orderId);
    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${orderId}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resultcart:", result);
        setCart(JSON.parse(result[0]?.shipment_list[0]?.details));
        setCustomerInfo(result[0]?.shipment_details[0]);
      });
  }, [orderId]);

  const handleUpdate = () => {
    // let newObject = localStorage.getItem("wmData");
    // let parsedObject = JSON.parse(newObject);
    // newOrderInfo.p_user = parsedObject?.SopnoID;

    const formData = new FormData();
    formData.append("cart", JSON.stringify(cart));
    formData.append(
      "charge",
      Math.round(
        cart?.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseFloat(currentValue?.quantity) *
              parseFloat(currentValue?.price),
          0
        ) * 0.05
      )
    );
    formData.append(
      "subTotal",
      Math.round(
        cart?.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseFloat(currentValue?.quantity) *
              parseFloat(currentValue?.price),
          0
        )
      )
    );
    formData.append(
      "totalAmount",
      Math.round(
        cart?.reduce(
          (accumulator, currentValue) =>
            accumulator +
            parseFloat(currentValue?.quantity) *
              parseFloat(currentValue?.price),
          0
        ) *
          0.05 +
          cart?.reduce(
            (accumulator, currentValue) =>
              accumulator +
              parseFloat(currentValue?.quantity) *
                parseFloat(currentValue?.price),
            0
          )
      )
    );

    fetch(
      `http://saraelectro.alqamaralzhabi.com/admin_api/booking_api/custom_order_update.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.m_type) {
          swal({
            title: result?.m_result,
            text: result?.q_message,
            icon: result?.m_type,
            button: "Done",
          });
        }
        handleClose();
        navigate("/upload");
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const handleChange = (index, event) => {
    let isFieldValid = false;
    console.log("event.target.value:", event.target.value);

    if (event.target.name === "price") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }

    if (event.target.name === "quantity") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      let data = [...cart];
      data[index][event.target.name] = event.target.value;
      setCart(data);
    }
    if (!isFieldValid) {
      //   const newField = { ...inputField };
      //   newField.fieldErrorAlert = event.target.name;
      //   newField[event.target.name] = "";
      //   setInputField(newField);
      let data = [...cart];
      data[index][event.target.name] = event.target.value;
      setCart(data);
    }
  };

  return (
    <Box mb={6}>
      <Container>
        <Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            style={{ width: "100%" }}
            spacing={1}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              style={{ width: "80%" }}
              //   spacing={1}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "30%",
                  borderRadius: "0px",
                  textTransform: "capitalize",
                  bgcolor: btn === "customerInfo" ? "green" : "",
                  color: btn === "customerInfo" ? "white" : "",
                  "&:hover": {
                    color: "black",
                  },
                }}
                onClick={() => {
                  setBtn("customerInfo");
                }}
              >
                Update Customer Information
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: "30%",
                  borderRadius: "0px",
                  textTransform: "capitalize",
                  bgcolor: btn === "orderInfo" ? "green" : "",
                  color: btn === "orderInfo" ? "white" : "",
                  "&:hover": {
                    color: "black",
                  },
                }}
                onClick={() => {
                  setBtn("orderInfo");
                }}
              >
                Update Order Information
              </Button>
            </Stack>
          </Stack>

          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            style={{ width: "100%", marginTop: "40px" }}
          >
            {btn === "orderInfo" ? (
              <Stack
                alignItems="center"
                justifyContent={"center"}
                style={{ width: "80%", backgroundColor: "" }}
              >
                <Paper sx={{ padding: "20px" }}>
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "12pt",
                            }}
                          >
                            ITEM DESCRIPTION
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "12pt",
                            }}
                          >
                            Quantity
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "12pt",
                            }}
                          >
                            Rate
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={2}>
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "12pt",
                            }}
                          >
                            Amount
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: "0px" }}>
                      {cart?.map((element, index) => {
                        return (
                          <Grid item xs={12}>
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={1}
                                    placeholder="Enter your address"
                                    value={element?.title}
                                    name="title"
                                    className="customTextField"
                                    style={{
                                      fontSize: "9pt",
                                      fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                                      width: "100%",
                                      maxWidth: "100%",
                                      backgroundColor: "#D4EAFF",
                                      boxSizing: "border-box",
                                      border: "1px solid rgba(0, 0, 0, 0.23)",
                                      borderRadius: "6px",
                                      paddingLeft: "15px",
                                      paddingRight: "15px",
                                      paddingTop: "8px",
                                      paddingBottom: "8px",
                                    }}
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <OutlinedInput
                                    value={element?.quantity}
                                    type="number"
                                    name="quantity"
                                    size="small"
                                    placeholder="Enter quantity"
                                    sx={{
                                      width: "100%",
                                      bgcolor: "#D4EAFF",
                                      borderRadius: "6px",
                                      fontSize: "9pt",
                                    }}
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <OutlinedInput
                                    value={element?.price}
                                    type="number"
                                    name="price"
                                    size="small"
                                    placeholder="Enter quantity"
                                    sx={{
                                      width: "100%",
                                      bgcolor: "#D4EAFF",
                                      borderRadius: "6px",
                                      fontSize: "9pt",
                                    }}
                                    onChange={(e) => {
                                      handleChange(index, e);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <OutlinedInput
                                    value={Math.round(
                                      parseFloat(element?.price) *
                                        parseFloat(element?.quantity)
                                    )}
                                    type="number"
                                    name="price"
                                    size="small"
                                    placeholder="Enter quantity"
                                    sx={{
                                      width: "100%",
                                      bgcolor: "#D4EAFF",
                                      borderRadius: "6px",
                                      fontSize: "9pt",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"right"}
                      style={{ width: "100%" }}
                    >
                      <Stack
                        alignItems="flex-end"
                        justifyContent={"center"}
                        style={{
                          width: "25%",
                          borderTop: "2px solid gray",
                          marginTop: "10px",
                          paddingTop: "5px",
                        }}
                      >
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                            textAlign: "right",
                          }}
                        >
                          Subtotal :{" "}
                          <strong>
                            {" "}
                            {Math.round(
                              cart?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator +
                                  parseFloat(currentValue?.quantity) *
                                    parseFloat(currentValue?.price),
                                0
                              )
                            )}
                          </strong>
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                          }}
                        >
                          Tax or vat(5%) :{" "}
                          <strong>
                            {Math.round(
                              cart?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator +
                                  parseFloat(currentValue?.quantity) *
                                    parseFloat(currentValue?.price),
                                0
                              ) * 0.05
                            )}
                          </strong>
                        </Typography>
                        <Typography
                          variant="p"
                          sx={{
                            fontSize: "12pt",
                          }}
                        >
                          Total :{" "}
                          <strong>
                            {" "}
                            {Math.round(
                              cart?.reduce(
                                (accumulator, currentValue) =>
                                  accumulator +
                                  parseFloat(currentValue?.quantity) *
                                    parseFloat(currentValue?.price),
                                0
                              ) *
                                0.05 +
                                cart?.reduce(
                                  (accumulator, currentValue) =>
                                    accumulator +
                                    parseFloat(currentValue?.quantity) *
                                      parseFloat(currentValue?.price),
                                  0
                                )
                            )}
                          </strong>
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      alignItems="center"
                      justifyContent={"right"}
                      style={{ width: "100%" }}
                    >
                      <Stack
                        alignItems="flex-end"
                        justifyContent={"center"}
                        style={{
                          width: "25%",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ textTransform: "capitalize", width: "38%" }}
                          onClick={handleOpen}
                        >
                          Update
                        </Button>
                      </Stack>
                    </Stack>
                  </>
                </Paper>
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                justifyContent={"center"}
                style={{ width: "50%", backgroundColor: "" }}
              >
                <Paper sx={{ padding: "20px" }}>
                  <UpdateCustomerInfo
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                  />
                </Paper>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Container>
      <UpdateConfirmModal
        handleClose={handleClose}
        open={open}
        type1={"Update Order Information Confirmation"}
        type2={"Do you want to update the order information"}
        handleUpdate={handleUpdate}
      />
    </Box>
  );
};

export default UpdateOrder;
